import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./RangeVisitDetail.css";
import SearchBox from "../../../common/GoogleMapSearch/GoogleMapSearch";
import "react-datepicker/dist/react-datepicker.css";
import { useLoaderData } from "react-router-dom";
import MapImage from "../../../common/MapImage/MapImage";
import moment from "moment";
import AddItemForm from "../../../common/AddRangeVisitItem/AddRangeVisitItem";
import { v4 as uuidv4 } from "uuid";
import { ImageModalInGrid } from "../../../common/Image/ImageModalInGrid";
import { useNavigate } from "react-router-dom";
import { decryptItem } from "../../../common/AESEncrypt/decryptionUtils";
import { getPasscode } from "../../../common/AESEncrypt/passcodeUtils";
import { encryptObject } from "../../../common/AESEncrypt/encryptionUtils";
import { getFirearms } from "../../../common/Data/dataUtils";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
async function loader({ request, params }) {
  const { id } = params;
  const rangevisitRequest = fetch("/api/rangevisit/" + id, {
    signal: request.signal,
    method: "GET",
  });

  const firearmsRequest = fetch("/api/firearms", {
    signal: request.signal,
    method: "GET",
    headers: {
      userid: "",
    },
  });

  const [result1, result2] = await Promise.all([
    rangevisitRequest,
    firearmsRequest,
  ]);
  if (result1.ok && result2.ok) {
    const [rangevisitdata, encryptedFirearms] = await Promise.all([
      result1.json(),
      result2.json(),
    ]);
    return { rangevisitdata, encryptedFirearms };
  } else {
    // this is just going to trigger the 404 page, but we can fix that later :|
    throw new Response("ERROR");
  }
}

function App() {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    // let access = true;
    let tempMessage = "";
    if (userInfo) {
      //Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#rangeVisitDetailPage",
      intro: "This is the detail page of your selected range visit",
    },
    {
      element: "#rangeVisitInfo",
      intro:
        "Here you will find the shooting range name and location, the date of visit, and the duration of visit. Click the `Edit` button to edit the range visit info. If you want to delete the range visit, click the `Delete` button.",
    },
    {
      element: "#activity",
      intro:
        "This area shows the activity detail for this range visit. Click the `ADD DETAIL` to add a new item to your range visit.",
    },
    {
      element: "#viewSwitch",
      intro:
        "Click the button to switch between activity and the gallery for the range visit. For the gallery view, you can add images of your range visit. ",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const { rangevisitdata, encryptedFirearms } = useLoaderData();
  const [rangevisit, setRangevisit] = useState(rangevisitdata);
  const [visitDate, setVisitDate] = useState(
    new Date(rangevisit.visitDate) || new Date()
  );
  const [visitDuration, setVisitDuration] = useState(rangevisit.duration);
  const [visitLat, setVisitLat] = useState(rangevisit.rangeLat);
  const [visitLng, setVisitLng] = useState(rangevisit.rangeLng);
  const [visitRangeInfo, setVisitRangeInfo] = useState(rangevisit.rangeInfo);
  const [visitDetail, setVisitDetail] = useState(rangevisit.visitDetail);
  const [visitGallery, setVisitGallery] = useState(rangevisit.visitGallery);
  const [isUploadImage, setIsUploadImage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const [isImageGalleryModalOpen, setImageGalleryModalOpen] = useState(false);
  const [galleryDisplayImageSrc, setGalleryDisplayImageSrc] = useState(null);
  const [firearmsdata, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };
  useEffect(() => {
    fetchFirearms();
  }, []);
  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    return response.uploadURL;
  };

  useEffect(() => {
    if (visitRangeInfo) {
      setVisitLat(visitRangeInfo.coordinates.lat);
      setVisitLng(visitRangeInfo.coordinates.lng);
    }
  }, [visitRangeInfo]);

  const handleRangeInfo = (info) => {
    setVisitRangeInfo(info); // Update the state when the child passes data back
  };

  async function handleSubmit(firearmid, value, notes) {
    setShowLoading(true);
    const newDetail = {
      firearm: firearmid,
      value: value,
      notes: notes,
      id: uuidv4(),
    };
    console.log(newDetail);
    const updatedVisitDetails = [...visitDetail, newDetail];
    setVisitDetail(updatedVisitDetails);
    // Use the updated array for the PUT request
    const updateRangeVisit = {
      visitDetail: updatedVisitDetails,
    };

    await fetch("/api/rangevisit/" + rangevisit._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateRangeVisit),
    });
    let currentFirearm = firearmsdata.find((item) => item._id === firearmid);
    console.log(currentFirearm);
    if (!currentFirearm.firearmRangeVisitHistory.includes(rangevisit._id)) {
      // Create a new array with the new ID added
      const updatedRangeVisitHistory = [
        ...currentFirearm.firearmRangeVisitHistory,
        rangevisit._id,
      ];
      console.log("ENcrypt data");
      currentFirearm["firearmRangeVisitHistory"] = updatedRangeVisitHistory;
      const passcode = getPasscode();
      const { _id, userid, firearmRangeVisitHistory, ...firearmData } =
        currentFirearm;

      // Encrypt the remaining firearm data
      const encryptedFirearm = encryptObject(firearmData, passcode);

      // Add _id and userid back to the encrypted data
      const encryptedFirearmWithId = {
        _id,
        userid,
        firearmRangeVisitHistory,
        ...encryptedFirearm,
      };
      await fetch("/api/firearm/" + firearmid, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(encryptedFirearmWithId),
      });
      console.log("Store new data to session");
      const updatedFirearms = firearmsdata.map((firearm) =>
        firearm._id === firearmid ? currentFirearm : firearm
      );
      sessionStorage.setItem("firearms", JSON.stringify(updatedFirearms));
    }
    setShowLoading(false);
  }

  async function handleDelete(id) {
    setShowLoading(true);
    // Filter out the item with the given id
    const updatedVisitDetails = visitDetail.filter(
      (detail) => detail.id !== id
    );
    const removedItem = visitDetail.find((detail) => detail.id === id);

    let isDuplicateFirearm = false;
    if (removedItem) {
      isDuplicateFirearm = updatedVisitDetails.some(
        (detail) => detail.firearm === removedItem.firearm
      );
    }
    console.log(isDuplicateFirearm);

    setVisitDetail(updatedVisitDetails);
    const updateRangeVisit = {
      visitDetail: updatedVisitDetails,
    };
    const passcode = getPasscode();
    await fetch("/api/rangevisit/" + rangevisit._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateRangeVisit),
    });

    if (!isDuplicateFirearm) {
      let currentFirearm = firearmsdata.find(
        (item) => item._id === removedItem.firearm
      );
      if (currentFirearm.firearmRangeVisitHistory.includes(rangevisit._id)) {
        const updatedRangeVisitHistory =
          currentFirearm.firearmRangeVisitHistory.filter(
            (id) => id !== rangevisit._id
          );
        currentFirearm["firearmRangeVisitHistory"] = updatedRangeVisitHistory;

        const { _id, userid, firearmRangeVisitHistory, ...firearmData } =
          currentFirearm;

        // Encrypt the remaining firearm data
        const encryptedFirearm = encryptObject(firearmData, passcode);

        // Add _id and userid back to the encrypted data
        const encryptedFirearmWithId = {
          _id,
          userid,
          firearmRangeVisitHistory,
          ...encryptedFirearm,
        };
        console.log(encryptedFirearmWithId);
        await fetch("/api/firearm/" + removedItem.firearm, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(encryptedFirearmWithId),
        });
      }
    }
    setShowLoading(false);
  }

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const cancelEdit = () => {
    console.log("Cancel update");
    setVisitDate(new Date(rangevisit.visitDate));
    setVisitDuration(rangevisit.duration);
    setVisitLat(rangevisit.rangeLat);
    setVisitLng(rangevisit.rangeLng);
    setIsEditing(!isEditing);
  };

  async function confirmEdit() {
    console.log("Confirm update");
    const formattedDate = visitDate.toISOString();
    const updateRangeVisit = {
      visitDate: formattedDate,
      rangeLat: visitLat,
      rangeLng: visitLng,
      duration: visitDuration,
      rangeInfo: visitRangeInfo,
    };

    const result = await fetch("/api/rangevisit/" + rangevisit._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateRangeVisit),
    });

    if (result.ok) {
      const rangevisitRequest = await fetch(
        "/api/rangevisit/" + rangevisit._id,
        {
          method: "GET",
        }
      );
      const newRangeVisit = await rangevisitRequest.json();
      console.log(newRangeVisit);
      setRangevisit(newRangeVisit);
    }
    setIsEditing(!isEditing);
  }

  const handleDateChange = (date) => {
    setVisitDate(date);
  };

  const handleDurationChange = (event) => {
    setVisitDuration(event.target.value); // Update duration based on user input
  };

  const [showGallery, setShowGallery] = useState(false);

  const toggleView = () => {
    setShowGallery((prev) => !prev);
  };

  const handleAddImageClick = () => {
    setIsUploadImage(true);
    if (window.confirm("Do you want to add a new image to the gallery?")) {
      setShowLoading(true);
      console.log("here in the window");
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/png, image/jpg, image/jpeg";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const newImageUplaodUrl = await setCloudFlareUploadURL();
          if (!newImageUplaodUrl) {
            console.error("Upload URL is not set.");
            setShowLoading(false);
            return;
          }

          // Upload the new image
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await fetch(newImageUplaodUrl, {
            method: "POST",
            body: formData,
          });

          if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            if (
              !data.result ||
              !data.result.variants ||
              data.result.variants.length === 0
            ) {
              setShowLoading(false);
              throw new Error("No variants found in the upload response.");
            }
            const newImageURL = data.result.variants[0];
            console.log(newImageURL);

            const updatedVisitGallery = [...visitGallery, newImageURL];
            setVisitGallery(updatedVisitGallery);
            // Use the updated array for the PUT request
            const updateRangeVisit = {
              visitGallery: updatedVisitGallery,
            };

            await fetch("/api/rangevisit/" + rangevisit._id, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updateRangeVisit),
            });
            setShowLoading(false);
          }
        }
      };
      fileInput.click();
    }
    // You can open a modal or directly trigger a file input click event here
    console.log("Add image button clicked");
  };

  const handleImageGalleryClick = (imageUrl) => {
    setGalleryDisplayImageSrc(imageUrl);
    setImageGalleryModalOpen(true);
  };

  async function handleDeleteGalleryImage() {
    setIsUploadImage(true);
    if (window.confirm("Do you want to delete the image from the gallery?")) {
      setShowLoading(true);
      const parts = galleryDisplayImageSrc.split("/");
      const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

      // Call API to delete the image on Cloudflare
      const imageDeleteResponse = await fetch(
        `/api/delete/cloudflare/url/${imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!imageDeleteResponse.ok) {
        const error = await imageDeleteResponse.json();
        console.error(`Failed to delete image: ${error.message}`);
        setShowLoading(false);
      } else {
        console.log("Image deleted successfully.");
      }

      const updatedVisitGallery = visitGallery.filter(
        (url) => url !== galleryDisplayImageSrc
      );

      setVisitGallery(updatedVisitGallery);
      // Use the updated array for the PUT request
      const updateRangeVisit = {
        visitGallery: updatedVisitGallery,
      };

      await fetch("/api/rangevisit/" + rangevisit._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateRangeVisit),
      });
      setShowLoading(false);
    }
    setImageGalleryModalOpen(false);
  }

  const handleDeleteRangeVisit = async () => {
    // Confirm before proceeding with the deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this range visit? Deleting this will also remove all associated range visit details and images in the gallery."
    );

    if (isConfirmed) {
      // User clicked OK
      console.log(rangevisit._id);

      // Proceed with the deletion logic here
      try {
        // Assuming you have a function to call your API to delete the firearm
        setShowLoading(true);

        const deletePromises = visitGallery.map((imageUrl) => {
          const parts = imageUrl.split("/");
          const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

          return fetch(`/api/delete/cloudflare/url/${imageId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          });
        });

        // Wait for all delete operations to complete
        const results = await Promise.all(deletePromises);

        // Check all responses to ensure they were successful
        const allDeletedSuccessfully = results.every((response) => response.ok);
        if (!allDeletedSuccessfully) {
          console.error("Some images could not be deleted.");
        } else {
          console.log("All gallery images have been removed");
        }

        await fetch("/api/rangevisit/" + rangevisit._id, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });

        //Now we need to update the related firearms to remove the range visit log
        const passcode = getPasscode();
        const updatedFirearms = [];

        for (const firearm of firearmsdata) {
          if (firearm.firearmRangeVisitHistory.includes(rangevisit._id)) {
            const updatedFirearm = {
              ...firearm,
              firearmRangeVisitHistory: firearm.firearmRangeVisitHistory.filter(
                (id) => id !== rangevisit._id
              ),
            };

            const { _id, userid, firearmRangeVisitHistory, ...firearmData } =
              updatedFirearm;

            // Encrypt the remaining firearm data
            const encryptedFirearm = encryptObject(firearmData, passcode);

            // Add _id and userid back to the encrypted data
            const encryptedFirearmWithId = {
              _id,
              userid,
              firearmRangeVisitHistory,
              ...encryptedFirearm,
            };

            // Make the PUT request to update the firearm
            await fetch("/api/firearm/" + encryptedFirearmWithId._id, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(encryptedFirearmWithId),
            });

            updatedFirearms.push(updatedFirearm);
          } else {
            updatedFirearms.push(firearm);
          }
        }

        // Update the firearms in session storage
        sessionStorage.setItem("firearms", JSON.stringify(updatedFirearms));

        setShowLoading(false);
        // Navigate to the home page after successful deletion
        navigate("/rangevisit/dashboard");
      } catch (error) {
        console.error("Failed to delete the range visit:", error);
        setShowLoading(false);
        // Handle errors, maybe show a message to the user
      }
    } else {
      // User clicked Cancel, handle accordingly
      console.log("Deletion canceled.");
    }
  };

  return (
    <div className="page-container rangevisit-detail-custom-page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      {/* Semi-transparent background image overlay */}
      <div className="page-background rangevisit-dashboard-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isImageGalleryModalOpen && (
        <ImageModalInGrid
          src={galleryDisplayImageSrc}
          onClose={() => setImageGalleryModalOpen(false)}
          onSwitch={handleDeleteGalleryImage} // Define this function based on your needs
        />
      )}

      {/* Visit item */}

      <div className="page-left-container" id="rangeVisitInfo">
        {isEditing ? (
          <div className="all-input-container">
            <div className="map-container">
              {!visitLat || !visitLng ? (
                <h1>Loading...</h1>
              ) : (
                <SearchBox
                  lat={visitLat}
                  lng={visitLng}
                  onRangeInfo={handleRangeInfo}
                />
              )}
            </div>
            <div className="inputs-container">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={visitDate}
                onChange={handleDateChange}
                placeholderText="Your date of visit"
                className="custom-datepicker"
              />
              <div className="duration-container">
                <input
                  type="number"
                  value={visitDuration}
                  onChange={handleDurationChange}
                  placeholder="Duration in minutes"
                  className="duration-input"
                />
                <span className="duration-label">min</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="map-container">
              <MapImage lat={visitLat} lng={visitLng} />
              <div className="info-container">
                <a
                  href={visitRangeInfo.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="info-link"
                >
                  <p>{visitRangeInfo.name}</p>
                </a>
                <p>{visitRangeInfo.address}</p>
                <p className="date">{moment(visitDate).format("YYYY-MM-DD")}</p>
                <p className="duration">{visitDuration} min</p>
              </div>
            </div>
          </div>
        )}
        {isEditing && (
          <div className="button-container">
            <button className="button is-primary" onClick={cancelEdit}>
              Cancel
            </button>
            <button className="button is-primary" onClick={confirmEdit}>
              Update
            </button>
          </div>
        )}
        {!isEditing && canAddNewFirearm === true && (
          <button className="edit-button is-primary" onClick={toggleEditMode}>
            Edit
          </button>
        )}
        {!isEditing && (
          <button
            className="edit-button is-primary"
            onClick={handleDeleteRangeVisit}
          >
            Delete
          </button>
        )}
      </div>

      {/* Detail list */}

      <div
        className="page-right-container custom-page-right-container"
        id="activity"
      >
        <div className="toggle-button-container" id="viewSwitch">
          <button onClick={toggleView}>
            {showGallery ? "Detail" : "Gallery"}
          </button>
        </div>
        {!showGallery ? (
          <>
            {canAddNewFirearm === true && (
              <AddItemForm
                handleSubmit={handleSubmit}
                firearmlist={firearmsdata}
              />
            )}
            {visitDetail.length > 0 &&
              visitDetail.map((detail, index) => {
                const firearm = firearmsdata.find(
                  (f) => f._id === detail.firearm
                );
                const handleButtonClick = (e) => {
                  e.stopPropagation(); // Prevent the event from bubbling up to the Link
                  handleDelete(detail.id); // Proceed with the deletion
                };
                return (
                  <div key={detail.id} className="range-visit-detail-item">
                    <div className="range-visit-detail-main">
                      <button
                        className="range-visit-detail-list-delete-button"
                        onClick={handleButtonClick}
                        aria-label="Remove item"
                      >
                        ✕
                      </button>
                      <span className="range-visit-detail-list-firearm">
                        {firearm ? firearm.firearmName : "Unknown Firearm"}
                      </span>
                      <span className="range-visit-detail-list-value">
                        {detail.value} rounds
                      </span>
                    </div>
                    <div className="range-visit-detail-notes">
                      {detail.notes}
                    </div>
                  </div>
                );
              })}
          </>
        ) : (
          <>
            <div className="firearm-detail-section">
              <h2 className="firearm-detail-title">Gallery</h2>
              <div className="firearm-detail-gallery-grid">
                {/* Display images from the gallery list */}
                {visitGallery &&
                  visitGallery.map((imageUrl, index) => (
                    <div
                      className="firearm-detail-gallery-item"
                      key={index}
                      onClick={() => handleImageGalleryClick(imageUrl)}
                    >
                      <img key={index} src={imageUrl} />
                    </div>
                  ))}
                {canAddNewFirearm === true && (
                  <div
                    className="firearm-detail-gallery-item firearm-detail-gallery-add-image-button"
                    onClick={handleAddImageClick}
                  >
                    <span>+</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Rangevisit_Detail = {
  path: "/rangevisit/:id",
  element: <App></App>,
  loader: loader,
};
