import "./App.css";
import "./common/style.css";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Carousel from "./common/FrontPageImage/FrontPageImage";
import PasscodePrompt from "./common/AESEncrypt/PasscodePrompt";
import { storePasscode, getPasscode } from "./common/AESEncrypt/passcodeUtils";
import { loadFirearms } from "./common/Data/dataUtils";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
function App() {
  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setEnabled(false);
  };

  const onHelpClick = () => {
    setEnabled(true);
  };
  const steps = [
    {
      element: "#Inventory",
      intro: "You can use this button to access your firearm inventory",
    },
    {
      element: "#Ammo",
      intro: "You can use this button to access your ammo storage",
    },
    {
      element: "#Accessory",
      intro: "You can use this button to access your accessories",
    },
    {
      element: "#Modification",
      intro: "You can use this button to access your modification history",
    },
    {
      element: "#Maintenance",
      intro: "You can use this button to access your maintenance history",
    },
    {
      element: "#RangeVisit",
      intro: "You can use this button to access your range visit history",
    },
    {
      element: "#Calendar",
      intro: "You can use this button to access your calendar",
    },
    {
      element: "#Subscription",
      intro: "You can use this button to access your subscription info",
    },
  ];
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectToHomeNotLogin = () => navigate("/", { replace: true });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const checkPasscode = () => {
      const storedPasscode = getPasscode();
      if (storedPasscode) {
        setIsAuthenticated(true);
        console.log(storedPasscode);
      }
    };

    if (!isAuthenticated) {
      const intervalId = setInterval(checkPasscode, 50); // Check every second

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log("load firearms");
      loadFirearms(`/api/firearms`, "firearms");
      console.log("finish load firearms");
    }
  }, [isAuthenticated]);

  const handlePasscodeSubmit = (passcode) => {
    console.log("handlePasscodeSubmit called with passcode:", passcode);
    storePasscode(passcode);
  };

  const findClaimValue = (claims, claimType) => {
    const claim = claims.find((c) => c.typ === claimType);
    return claim ? claim.val : null;
  };

  // Modified async function to fetch and extract user information
  async function getUserInfo() {
    try {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const { clientPrincipal } = payload;

      // Extract relevant fields to create a custom userInfo object
      const userInfo = {
        userId: clientPrincipal.userId,
        userDetails: findClaimValue(
          clientPrincipal.claims,
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
        ),
        email: findClaimValue(clientPrincipal.claims, "emails"),
        roles: clientPrincipal.userRoles,
        identityProvider: clientPrincipal.identityProvider,
      };

      return userInfo; // Return the custom user info object
    } catch (error) {
      console.error("No profile could be found:", error);
      return undefined;
    }
  }

  useEffect(() => {
    (async () => {
      const user = await getUserInfo();
      setUserInfo(user);

      // If not logged in, redirect to home page
      if (location.pathname === "/contact") {
      } else if (!user) {
        redirectToHomeNotLogin();
      } else {
        if (location.pathname === "/") {
          navigate("/home", { replace: true });
        }
      }
    })();
  }, []);

  return (
    <div className="App color4-back">
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      <div className="top-bar color6-back">
        <div className="header-button-left color1-back">
          {userInfo && (
            <Link to="/home" className="color4 nodecoration">
              Welcome back, {userInfo.userDetails}
            </Link>
          )}
          {!userInfo && (
            <Link to="/" className="color4 nodecoration">
              ArmorySync
            </Link>
          )}
        </div>
        <div className="header-button-group-right">
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/firearm/list"
                className="header-button-link"
                id="Inventory"
              >
                Inventory
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link to="/ammo/list" className="header-button-link" id="Ammo">
                Ammo
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/accessory/list"
                className="header-button-link"
                id="Accessory"
              >
                Accessory
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/modification/list"
                className="header-button-link"
                id="Modification"
              >
                Modification
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/maintenance/list"
                className="header-button-link"
                id="Maintenance"
              >
                Maintenance
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/rangevisit/dashboard"
                className="header-button-link"
                id="RangeVisit"
              >
                Range Visit
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link to="/calendar" className="header-button-link" id="Calendar">
                Calendar
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <Link
                to="/subscription"
                className="header-button-link"
                id="Subscription"
              >
                Subscription
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {!userInfo && (
              <Link
                to="/whats-new"
                className="header-button-link"
                id="NewUpdates"
              >
                What's New?
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {!userInfo && (
              <Link to="/faq" className="header-button-link" id="FAQ">
                FAQ
              </Link>
            )}
          </div>
          {/* <div className="header-button-container">
            {!userInfo && (
              <Link to="/contact" className="header-button-link" id="Contact">
                Blog
              </Link>
            )}
          </div> */}
          <div className="header-button-container">
            {!userInfo && (
              <Link to="/contact" className="header-button-link" id="Contact">
                Contact
              </Link>
            )}
          </div>
          <div className="header-button-container">
            {!userInfo && (
              <a
                href="/.auth/login/aadb2c?post_login_redirect_uri=/home"
                //href="/.auth/login/github?post_login_redirect_uri=/home"
                className="header-button-link"
                id="Login"
              >
                Login
              </a>
            )}
          </div>

          <div className="header-button-container">
            {userInfo && (
              <button onClick={onHelpClick} className="header-button-link">
                Help
              </button>
            )}
          </div>
          <div className="header-button-container">
            {userInfo && (
              <a
                href="/.auth/logout"
                className="header-button-link logout-button-link"
              >
                Log out
              </a>
            )}
          </div>
        </div>
      </div>
      {!userInfo && location.pathname === "/" && <Carousel />}{" "}
      {/* Show carousel if user isn't logged in */}
      {userInfo && !isAuthenticated && (
        <PasscodePrompt onPasscodeSubmit={handlePasscodeSubmit} />
      )}
      <Outlet />
    </div>
  );
}

export default App;
