import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css"; // Ensure Bulma is imported if not already globally
import DatePicker from "react-datepicker";
import "./AddAccessoryItem.css";
import "../style.css";
import { getPasscode } from "../AESEncrypt/passcodeUtils";
import { getFirearms } from "../Data/dataUtils";
function AddAccessoryItem({ isOpen, onClose }) {
  const [notification, setNotification] = useState({
    message: "Acquisition Date is required.",
    visible: false,
  });
  const [accessoryData, setAccessoryData] = useState({
    accessoryName: "",
    accessoryNote: "",
    accessoryImage: "",
    accessoryAcquisitionPrice: "",
    accessoryAcquisitionMethod: "",
    accessoryAcquisitionSource: "",
    accessoryAcquisitionDate: "",
    accessoryAcquisitionProof: "",
    accessoryLinkedFirearm: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firearms, setFirearms] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setAccessoryData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setAccessoryData((prev) => ({ ...prev, accessoryAcquisitionDate: date }));
  };

  useEffect(() => {
    if (isOpen) {
      setCloudFlareUploadURL();
    }
  }, [isOpen]);

  const handleCancel = () => {
    setAccessoryData({
      accessoryName: "",
      accessoryNote: "",
      accessoryAcquisitionPrice: "",
      accessoryAcquisitionMethod: "",
      accessoryAcquisitionSource: "",
      accessoryAcquisitionDate: "",
      accessoryAcquisitionProof: "",
      accessoryLinkedFirearm: "",
    });
    setImagePreview("");
    onClose();
  };
  const validateForm = () => {
    const newErrors = {};
    // List all fields that require validation
    const fields = ["accessoryAcquisitionDate"];
    fields.forEach((field) => {
      if (!accessoryData[field]) {
        newErrors[field] = `The ${field.replace(
          "accessory",
          ""
        )} field is required.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    if (response.uploadURL) {
      setUploadUrl(response.uploadURL);
    }
    return response.uploadURL;
  };

  const handleSubmit = async (event) => {
    const passcode = getPasscode();
    console.log(passcode);
    if (!passcode) {
      // Prompt the user to enter the passcode again
      return;
    }
    event.preventDefault();

    if (!validateForm()) {
      console.log("Not valid");
      //const errorMessages = await Object.values(errors).join(" "); // Join all error messages into one string
      const errorMessages = "Acquisition Date is required.";
      setNotification({
        message: errorMessages,
        visible: true,
      });
      setTimeout(() => {
        setNotification({
          message: "Acquisition Date is required.",
          visible: false,
        });
      }, 5000);
      return; // Prevent form submission if validation fails
    }
    setIsLoading(true);

    await setCloudFlareUploadURL();
    const URL = await uploadImage();

    const completeData = {
      ...accessoryData,
      accessoryImage: URL,
    };

    const response = await fetch("/api/accessory", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(completeData),
    });
    if (!response.ok) {
      throw new Error("Failed to submit accessory data");
    }
    console.log(response);
    setAccessoryData({
      accessoryName: "",
      accessoryNote: "",
      accessoryImage: "",
      accessoryAcquisitionPrice: "",
      accessoryAcquisitionMethod: "",
      accessoryAcquisitionSource: "",
      accessoryAcquisitionDate: "",
      accessoryAcquisitionProof: "",
      accessoryLinkedFirearm: "",
    });
    setImagePreview("");
    onClose();
    setIsLoading(false);
  };

  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchFirearms();
  }, []);

  const handleImageChange = (event) => {
    setIsImage(false);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
      setIsImage(true);
    }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    if (imagePreview) {
      const blob = await fetch(imagePreview).then((r) => r.blob());
      formData.append("file", blob);
    }
    if (uploadUrl) {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const data = await response.json();
      console.log(data);
      return data.result.variants[0];
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal is-active">
      {isLoading && <div className="loading-indicator">Processing...</div>}
      {notification.visible && (
        <div className="notification">{notification.message}</div>
      )}
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Accessory</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <form
            id="firearm-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="field">
              <label className="label">Accessory Image</label>
              <div className="control">
                <input
                  required
                  className="input"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp, image/svg" // Specify accepted file types
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </div>
            </div>

            <div className="field">
              <label className="label">Name</label>
              <div className="input-group">
                <input
                  required
                  className="input"
                  type="text"
                  name="accessoryName"
                  value={accessoryData.accessoryName}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Cost</label>
              <div className="input-group">
                <span className="input-group-prepend">$</span>
                <input
                  required
                  className="input"
                  type="number"
                  step="0.01"
                  name="accessoryAcquisitionPrice"
                  value={accessoryData.accessoryAcquisitionPrice}
                  onChange={handleInputChange}
                  placeholder="Enter cost"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Source</label>
              <div className="input-group">
                <input
                  required
                  className="input"
                  type="text"
                  name="accessoryAcquisitionSource"
                  value={accessoryData.accessoryAcquisitionSource}
                  onChange={handleInputChange}
                  placeholder="Enter source"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Method</label>
              <div className="select">
                <select
                  required
                  name="accessoryAcquisitionMethod"
                  value={accessoryData.accessoryAcquisitionMethod}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  <option value="Purchase">Purchase</option>
                  <option value="Gift">Gift</option>
                  <option value="Inheritance">Inheritance</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">Notes</label>
              <div className="control">
                <textarea
                  placeholder="Notes"
                  className="input input-notes"
                  type="text"
                  name="accessoryNote"
                  value={accessoryData.accessoryNote}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Acquisition Date</label>
              <div className="control">
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={accessoryData.accessoryAcquisitionDate}
                  onChange={handleDateChange}
                  placeholderText="Date of accessory"
                  className="custom-datepicker"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Which firearm?</label>
              <div className="select">
                <select
                  required
                  name="accessoryLinkedFirearm"
                  value={accessoryData.accessoryLinkedFirearm}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="none">No Firearm Linked</option>{" "}
                  {/* New option for no linked firearm */}
                  {firearms.map((firearm) => (
                    <option key={firearm._id} value={firearm._id}>
                      {firearm.firearmName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button
            className="add-firearm-button is-success"
            type="submit"
            form="firearm-form"
          >
            Add
          </button>
          <button
            className="add-firearm-button cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default AddAccessoryItem;
