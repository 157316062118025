import "../../common/style.css";
import "./WhatsNew.css";
import whatsNewData from "./whatsNewData.json";
import React from "react";

function WhatsNew() {
  return (
    <div className="whats-new-page-container">
      <div className="page-background whats-new-background-image"></div>
      <div className="whats-new-container">
        <h2>What’s New?</h2>
        {whatsNewData.length > 0 ? (
          whatsNewData.map((update, index) => (
            <div key={index} className="update-item">
              <h3>{update.title}</h3>
              <p>
                <strong>Release Date:</strong> {update.releaseDate}
              </p>
              <p>{update.description}</p>
            </div>
          ))
        ) : (
          <p>No updates available at this time.</p>
        )}
      </div>
    </div>
  );
}

export const WhatsNew_Page = {
  path: "/whats-new",
  element: <WhatsNew></WhatsNew>,
};
