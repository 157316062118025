import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "bulma/css/bulma.min.css";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./FirearmDetail.css"; // Ensure you import your custom styles here
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getFirearms } from "../../../common/Data/dataUtils";
import { ImageModal } from "../../../common/Image/ImageModal";
import { ImageModalInGrid } from "../../../common/Image/ImageModalInGrid";
import { decryptItem } from "../../../common/AESEncrypt/decryptionUtils";
import { getPasscode } from "../../../common/AESEncrypt/passcodeUtils";
import { encryptObject } from "../../../common/AESEncrypt/encryptionUtils";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
const FirearmDetails = () => {
  const steps = [
    {
      element: "#firearmDetailInfo",
      intro:
        "Here you will find all the info for this firearm. Click the button on the bottom to edit or delete the firearm. Click the images to zoom in and switch the image.",
    },
    {
      element: "#activityHistory",
      intro:
        "The firearm-associated range visit, maintenance, and modifications will be displayed here. ",
    },
    {
      element: "#viewSwitchButton",
      intro:
        "Click the button to switch between the gallery of the firearm and the activity history. In the gallery view, you can add/switch images of your firearms. It is recommended to add one image that showing the serial number of your firearm for insurance purpose. ",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const { firearmId } = useParams();
  const [firearm, setFirearm] = useState(null);
  const [newFirearm, setNewFirearm] = useState(null);
  const [rangeVisits, setRangeVisits] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [modifications, setModifications] = useState([]);
  const navigate = useNavigate();
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isImageGalleryModalOpen, setImageGalleryModalOpen] = useState(false);
  const [isSwitchImage, setIsSwitchImage] = useState(null);
  const [isUploadImage, setIsUploadImage] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [galleryDisplayImageSrc, setGalleryDisplayImageSrc] = useState(null);
  const [imageDisplayImageSrc, setImageDisplayImageSrc] = useState(null);
  const [imageDisplayImageType, setImageDisplayImageType] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const toggleView = () => {
    setShowGallery((prev) => !prev);
    setShowHistory((prev) => !prev);
  };

  useEffect(() => {
    const loadFirearmFromStorage = () => {
      const firearmsData = getFirearms("firearms");
      if (firearmsData) {
        const firearm = firearmsData.find((item) => item._id === firearmId);
        if (firearm) {
          setFirearm(firearm);
          setNewFirearm(firearm);
        } else {
          console.error("Firearm not found");
        }
      } else {
        console.error("No firearms data found in sessionStorage");
      }
    };

    loadFirearmFromStorage();
  }, [firearmId]);

  useEffect(() => {
    console.log(firearm);
    if (firearm && firearm.firearmRangeVisitHistory) {
      console.log(firearm.firearmRangeVisitHistory);
      fetch(`/api/rangevisit/byFirearmID/${firearmId}`)
        .then((response) => response.json())
        .then((data) => setRangeVisits(data))
        .catch((error) =>
          console.error("Error fetching firearm details:", error)
        );
      console.log(rangeVisits);
    }
  }, [firearm]);

  useEffect(() => {
    console.log(firearm);
    if (firearm) {
      fetch(`/api/maintenances/byFirearmID/${firearmId}`)
        .then((response) => response.json())
        .then((data) => setMaintenances(data))
        .catch((error) =>
          console.error("Error fetching firearm details:", error)
        );
    }
  }, [firearm]);

  useEffect(() => {
    console.log(firearm);
    if (firearm) {
      fetch(`/api/modifications/byFirearmID/${firearmId}`)
        .then((response) => response.json())
        .then((data) => setModifications(data))
        .catch((error) =>
          console.error("Error fetching firearm details:", error)
        );
    }
  }, [firearm]);

  useEffect(() => {
    console.log(modifications);
  }, [modifications]);

  const [latestMaintenanceDate, setLatestMaintenanceDate] = useState(
    "No maintenances recorded"
  );
  const [totalRoundsAfterLastMaintenance, setTotalRoundsAfterLastMaintenance] =
    useState(null);

  useEffect(() => {
    if (maintenances && rangeVisits) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Ensure all data processing is completed
      try {
        const filteredMaintenances = maintenances.filter(
          (maintenance) => new Date(maintenance.maintenanceDate) <= today
        );
        const latestMaintenance = filteredMaintenances.reduce(
          (latest, maintenance) => {
            const currentMaintenanceDate = new Date(
              maintenance.maintenanceDate
            );
            return !latest ||
              new Date(latest.maintenanceDate) < currentMaintenanceDate
              ? maintenance
              : latest;
          },
          null
        );

        if (latestMaintenance) {
          setLatestMaintenanceDate(
            moment(latestMaintenance.maintenanceDate).format("YYYY-MM-DD")
          );
        } else {
          setLatestMaintenanceDate("No maintenances recorded");
        }

        const totalRounds = latestMaintenance
          ? rangeVisits
              .filter(
                (visit) =>
                  new Date(visit.visitDate) >
                  new Date(latestMaintenance.maintenanceDate)
              )
              .reduce((acc, visit) => {
                const visitTotal = visit.visitDetail
                  .filter((detail) => detail.firearm === firearmId)
                  .reduce((acc, curr) => acc + Number(curr.value), 0);
                return acc + visitTotal;
              }, 0)
          : 0;

        setTotalRoundsAfterLastMaintenance(totalRounds);
      } finally {
        setShowLoading(false);
      }
    } else {
      setShowLoading(true);
    }
  }, [maintenances, rangeVisits, firearmId]);

  // useEffect(() => {
  //   if (totalRoundsAfterLastMaintenance) {
  //     console.log(totalRoundsAfterLastMaintenance);
  //     setShowLoading(false);
  //   }
  // }, [totalRoundsAfterLastMaintenance]);

  useEffect(() => {
    console.log("New firearm info edit");
    if (isSwitchImage && isSwitchImage === true) {
      console.log(newFirearm);
      confirmEdit();
      console.log("Firearm image updated and old image deleted successfully.");
      setIsSwitchImage(null);
      setShowLoading(false);
    } else if (isUploadImage && isUploadImage === true) {
      console.log("Adding additional image to gallery");
      confirmEdit();
      console.log("A new image has been added to the gallery");
      setIsUploadImage(null);
      setShowLoading(false);
    }
    setImageModalOpen(false);
  }, [newFirearm]);

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const cancelEdit = () => {
    console.log("Cancel update");
    toggleEditMode();
    setNewFirearm(firearm);
  };
  async function confirmEdit() {
    const passcode = getPasscode();
    console.log(passcode);
    if (!passcode) {
      // Prompt the user to enter the passcode again
      return;
    }
    setShowLoading(true);
    console.log("Confirm update");
    console.log(newFirearm);
    const { _id, firearmRangeVisitHistory, userid, ...firearmData } =
      newFirearm;

    // Encrypt the remaining firearm data
    const encryptedFirearm = encryptObject(firearmData, passcode);

    // Add _id and userid back to the encrypted data
    const encryptedFirearmWithId = {
      _id,
      userid,
      firearmRangeVisitHistory,
      ...encryptedFirearm,
    };
    console.log(encryptedFirearmWithId);
    const response = await fetch("/api/firearm/" + encryptedFirearmWithId._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(encryptedFirearmWithId),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error("Failed to submit firearm data");
    }
    const firearms = getFirearms("firearms") || [];
    const updatedFirearms = firearms.map((firearm) =>
      firearm._id === _id ? newFirearm : firearm
    );
    setFirearm(newFirearm);
    setIsEditing(false);
    setShowLoading(false);
    sessionStorage.setItem("firearms", JSON.stringify(updatedFirearms));
    //toggleEditMode();
  }

  const handleInfoEdit = (event) => {
    const { name, value } = event.target;
    setNewFirearm((prev) => ({ ...prev, [name]: value }));
    console.log(newFirearm);
  };

  const handleDateChange = (date) => {
    setNewFirearm((prev) => ({ ...prev, firearmAcquisitionDate: date }));
  };

  const handleDelete = async () => {
    // Confirm before proceeding with the deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this firearm? Deleting this will also remove all associated range visit details and maintenance history."
    );

    if (isConfirmed) {
      // User clicked OK
      console.log(firearm._id);

      // Proceed with the deletion logic here
      try {
        // Assuming you have a function to call your API to delete the firearm
        setShowLoading(true);
        await deleteFirearm(firearm._id, firearm.firearmImage);
        setShowLoading(false);
        // Navigate to the home page after successful deletion
        navigate("/firearm/list");
      } catch (error) {
        console.error("Failed to delete the firearm:", error);
        setShowLoading(false);
        // Handle errors, maybe show a message to the user
      }
    } else {
      // User clicked Cancel, handle accordingly
      console.log("Deletion canceled.");
    }
  };

  async function deleteFirearm(firearmId, imageUrl) {
    // First update the range visit details to remove references to this firearm
    const parts = imageUrl.split("/");
    console.log(imageUrl);
    const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

    // Call API to delete the image on Cloudflare
    const imageDeleteResponse = await fetch(
      `/api/delete/cloudflare/url/${imageId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!imageDeleteResponse.ok) {
      const error = await imageDeleteResponse.json();
      console.error(`Failed to delete image: ${error.message}`);
    } else {
      console.log("Image deleted successfully.");
    }

    const partsProof = firearm.firearmAcquisitionProof.split("/");
    const imageIdProof = partsProof[partsProof.length - 2]; // Assuming the ID is the second last part of the URL
    // Call API to delete the image on Cloudflare
    const imageDeleteResponseProof = await fetch(
      `/api/delete/cloudflare/url/${imageIdProof}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!imageDeleteResponseProof.ok) {
      const error = await imageDeleteResponseProof.json();
      console.error(`Failed to delete proof image: ${error.message}`);
    } else {
      console.log("Proof image deleted successfully.");
    }

    const deletePromises = firearm.firearmGallery.map((imageUrl) => {
      const parts = imageUrl.split("/");
      const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

      return fetch(`/api/delete/cloudflare/url/${imageId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    });

    // Wait for all delete operations to complete
    const results = await Promise.all(deletePromises);

    // Check all responses to ensure they were successful
    const allDeletedSuccessfully = results.every((response) => response.ok);
    if (!allDeletedSuccessfully) {
      console.error("Some images could not be deleted.");
    }

    const updateResponse = await fetch(
      `/api/rangevisit/byFirearmID/${firearmId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          /* appropriate payload if needed */
        }),
      }
    );

    if (updateResponse.ok) {
      console.log("Firearm references removed from range visits successfully.");

      // Now proceed to delete the firearm itself
      const deleteResponse = await fetch(`/api/firearm/${firearmId}`, {
        method: "DELETE",
      });

      if (deleteResponse.ok) {
        console.log("Firearm deleted successfully.");
        const firearms = getFirearms("firearms") || [];
        const updatedFirearms = firearms.filter(
          (firearm) => firearm._id !== firearmId
        );

        sessionStorage.setItem("firearms", JSON.stringify(updatedFirearms));
        // Handle further actions after successful deletion, e.g., navigating away or updating UI
        return true;
      } else {
        console.error("Failed to delete the firearm.");
        // Handle failure in deletion
        return false;
      }
    } else {
      console.error("Failed to update range visit details.");
      // Handle failure in updating range visits
      return false;
    }
  }
  const handleImageClick = (imageUrl, imageType) => {
    setImageDisplayImageSrc(imageUrl);
    setImageDisplayImageType(imageType);
    setImageModalOpen(true);
  };

  const handleImageGalleryClick = (imageUrl) => {
    setGalleryDisplayImageSrc(imageUrl);
    setImageGalleryModalOpen(true);
  };

  const [uploadUrl, setUploadUrl] = useState("");
  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    if (response.uploadURL) {
      setUploadUrl(response.uploadURL);
    }
    return response.uploadURL;
  };
  async function handleDeleteGalleryImage() {
    setIsUploadImage(true);
    if (window.confirm("Do you want to delete the image from the gallery?")) {
      setShowLoading(true);
      const parts = galleryDisplayImageSrc.split("/");
      const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

      // Call API to delete the image on Cloudflare
      const imageDeleteResponse = await fetch(
        `/api/delete/cloudflare/url/${imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!imageDeleteResponse.ok) {
        const error = await imageDeleteResponse.json();
        console.error(`Failed to delete image: ${error.message}`);
        setShowLoading(false);
      } else {
        console.log("Image deleted successfully.");
      }
      setNewFirearm((prev) => ({
        ...prev,
        firearmGallery: prev.firearmGallery.filter(
          (url) => url !== galleryDisplayImageSrc
        ),
      }));
    }
    setImageGalleryModalOpen(false);
  }
  const handleSwitch = () => {
    const imageTypeMessage =
      imageDisplayImageType === "firearmImage"
        ? "Do you want to switch the firearm item page image?"
        : "Do you want to switch the firearm acquisition proof?";

    setIsSwitchImage(true);
    if (window.confirm(imageTypeMessage)) {
      setShowLoading(true);
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/png, image/jpg, image/jpeg";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        console.log("Here");
        if (file) {
          const newImageUplaodUrl = await setCloudFlareUploadURL();
          console.log(newImageUplaodUrl);
          if (!newImageUplaodUrl) {
            console.error("Upload URL is not set.");
            setImageModalOpen(false);
            setShowLoading(false);
            return;
          }

          console.log("Start upload new image");
          // Upload the new image
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await fetch(newImageUplaodUrl, {
            method: "POST",
            body: formData,
          });
          console.log("Upload response ready to check");
          if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            if (
              !data.result ||
              !data.result.variants ||
              data.result.variants.length === 0
            ) {
              setImageModalOpen(false);
              setShowLoading(false);
              throw new Error("No variants found in the upload response.");
            }
            const newImageURL = data.result.variants[0];

            // Delete the original image
            const parts = imageDisplayImageSrc.split("/");
            const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL
            console.log(imageId);
            console.log(newImageURL);
            const imageDeleteResponse = await fetch(
              `/api/delete/cloudflare/url/${imageId}`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (!imageDeleteResponse.ok) {
              const error = await imageDeleteResponse.json();
              console.error(`Failed to delete image: ${error.message}`);
            } else {
              console.log("Image deleted successfully.");
            }

            // Update firearm with new image URL
            setNewFirearm((prev) => ({
              ...prev,
              [imageDisplayImageType]: newImageURL,
            }));
            // Wait a tick to ensure state update initiates
          } else {
            setImageModalOpen(false);
            setShowLoading(false);
            throw new Error("Failed to upload new image.");
          }
        } else {
          setImageModalOpen(false);
          setShowLoading(false);
        }
      };
      fileInput.click();
    }
  };

  const handleAddImageClick = () => {
    setIsUploadImage(true);
    if (window.confirm("Do you want to add a new image to the gallery?")) {
      setShowLoading(true);
      console.log("here in the window");
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/png, image/jpg, image/jpeg";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const newImageUplaodUrl = await setCloudFlareUploadURL();
          if (!newImageUplaodUrl) {
            console.error("Upload URL is not set.");
            setImageGalleryModalOpen(false);
            setShowLoading(false);
            return;
          }

          // Upload the new image
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await fetch(newImageUplaodUrl, {
            method: "POST",
            body: formData,
          });

          if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            if (
              !data.result ||
              !data.result.variants ||
              data.result.variants.length === 0
            ) {
              setImageGalleryModalOpen(false);
              setShowLoading(false);
              throw new Error("No variants found in the upload response.");
            }
            const newImageURL = data.result.variants[0];
            console.log(newImageURL);
            console.log(newFirearm);
            setNewFirearm((prev) => ({
              ...prev,
              firearmGallery: [...prev.firearmGallery, newImageURL], // Adds newImageURL to the existing gallery array
            }));
          }
        }
      };
      fileInput.click();
    }
    // You can open a modal or directly trigger a file input click event here
    console.log("Add image button clicked");
  };
  return (
    <div className="page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      <div className="page-background firearm-page-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isImageModalOpen && (
        <ImageModal
          src={imageDisplayImageSrc}
          alt={`Firearm ${firearm.firearmName}`}
          onClose={() => setImageModalOpen(false)}
          onSwitch={handleSwitch} // Define this function based on your needs
        />
      )}
      {isImageGalleryModalOpen && (
        <ImageModalInGrid
          src={galleryDisplayImageSrc}
          onClose={() => setImageGalleryModalOpen(false)}
          onSwitch={handleDeleteGalleryImage} // Define this function based on your needs
        />
      )}
      <div className="page-left-container" id="firearmDetailInfo">
        <div className="firearm-detail-box">
          {isEditing && (
            <div className="firearm-detail-card">
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Firearm Name</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmName"
                    value={newFirearm.firearmName}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Firearm Type</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmType"
                    value={newFirearm.firearmType}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Firearm Make</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmMake"
                    value={newFirearm.firearmMake}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Firearm Model</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmModel"
                    value={newFirearm.firearmModel}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Serial Number</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmSerialNumber"
                    value={newFirearm.firearmSerialNumber}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Caliber</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmCaliber"
                    value={newFirearm.firearmCaliber}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Price</label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmPrice"
                    value={newFirearm.firearmPrice}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">
                  Acquisition Method
                </label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmAcquisitionMethod"
                    value={newFirearm.firearmAcquisitionMethod}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">
                  Acquisition Source
                </label>
                <div className="firearm-detail-control">
                  <input
                    className="firearm-detail-input"
                    type="text"
                    name="firearmAcquisitionSource"
                    value={newFirearm.firearmAcquisitionSource}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="firearm-detail-field">
                <label className="firearm-detail-label">Acquisition Date</label>
                <div className="firearm-detail-control">
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={newFirearm.firearmAcquisitionDate}
                    onChange={handleDateChange}
                    placeholderText="Date of Purchase"
                    className="custom-datepicker"
                  />
                </div>
              </div>
            </div>
          )}
          {!isEditing &&
            (firearm ? (
              <div className="firearm-detail-card">
                <div
                  className="firearm-detail-card-image"
                  onClick={() =>
                    handleImageClick(firearm.firearmImage, "firearmImage")
                  }
                >
                  <figure className="firearm-detail-image is-4by3">
                    <img
                      src={firearm.firearmImage}
                      alt={`Firearm ${firearm.firearmName}`}
                    />
                  </figure>
                </div>
                <div className="firearm-detail-card-content">
                  <p className="firearm-detail-title is-4">
                    {firearm.firearmName}
                  </p>
                  <p className="firearm-detail-subtitle is-6">
                    <span className="firearm-detail-detail-label">Type:</span>{" "}
                    {firearm.firearmType}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Make:
                    </span>{" "}
                    {firearm.firearmMake}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Model:
                    </span>{" "}
                    {firearm.firearmModel}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Serial Number:
                    </span>{" "}
                    {firearm.firearmSerialNumber}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Caliber:
                    </span>{" "}
                    {firearm.firearmCaliber}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Price:
                    </span>{" "}
                    ${firearm.firearmPrice}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Acquisition Method:
                    </span>{" "}
                    {firearm.firearmAcquisitionMethod}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Acquisition Source:
                    </span>{" "}
                    {firearm.firearmAcquisitionSource}
                    <br />
                    <span className="firearm-detail-detail-label">
                      Acquisition Date:
                    </span>{" "}
                    {
                      new Date(firearm.firearmAcquisitionDate)
                        .toISOString()
                        .split("T")[0]
                    }
                  </p>
                  <p className="firearm-detail-title is-4">Acquisition Proof</p>
                </div>
                <div className="firearm-detail-card-image">
                  <figure
                    className="firearm-detail-image is-4by3"
                    onClick={() =>
                      handleImageClick(
                        firearm.firearmAcquisitionProof,
                        "firearmAcquisitionProof"
                      )
                    }
                  >
                    <img
                      src={firearm.firearmAcquisitionProof}
                      alt={`Firearm ${firearm.firearmName}`}
                    />
                  </figure>
                </div>
              </div>
            ) : (
              <p>Loading firearm details...</p>
            ))}
        </div>
        {isEditing && (
          <div className="button-container">
            <button className="button is-primary" onClick={cancelEdit}>
              Cancel
            </button>
            <button className="button is-primary" onClick={confirmEdit}>
              Update
            </button>
          </div>
        )}
        {!isEditing && (
          <div className="button-container">
            <button className="edit-button is-primary" onClick={toggleEditMode}>
              Edit
            </button>
            <button className="edit-button is-primary" onClick={handleDelete}>
              Delete
            </button>
          </div>
        )}
      </div>
      <div
        className="page-right-container firearm-detail-page-right-container"
        id="activityHistory"
      >
        <div className="toggle-button-container" id="viewSwitchButton">
          <button onClick={toggleView}>
            {showGallery ? "History" : "Gallery"}
          </button>
        </div>
        {!showGallery ? (
          <>
            <div className="firearm-detail-section">
              <h2 className="firearm-detail-title">Maintenance History</h2>
              <div className="firearm-detail-history-box">
                {firearm && maintenances && maintenances.length ? (
                  <div className="firearm-detail-content-flex">
                    <div className="firearm-detail-content-left">
                      <p>
                        Latest Maintenance Date:{" "}
                        <span className="firearm-detail-date-highlight">
                          {latestMaintenanceDate}
                        </span>
                      </p>
                      <p>
                        Total Rounds Fired Since Last Maintenance:{" "}
                        <span className="firearm-detail-rounds-highlight">
                          {totalRoundsAfterLastMaintenance}
                        </span>
                      </p>
                    </div>
                    <div className="firearm-detail-content-right">
                      <ul>
                        {maintenances
                          .sort(
                            (a, b) =>
                              new Date(b.maintenanceDate) -
                              new Date(a.maintenanceDate)
                          )
                          .map((item, index) => (
                            <li key={index}>
                              Date:{" "}
                              {moment(item.maintenanceDate).format(
                                "YYYY-MM-DD"
                              )}
                              <br /> {/* Line break between date and note */}
                              {item.maintenanceNote}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <p>No maintenance history available.</p>
                )}
              </div>
            </div>
            <div className="firearm-detail-section">
              <h2 className="firearm-detail-title">Range Visits</h2>
              <div className="firearm-detail-history-box">
                {rangeVisits.length ? (
                  <div className="firearm-detail-content-flex">
                    <div className="firearm-detail-content-left">
                      {/* Future content or additional details can go here */}
                      <div className="detail-section">
                        <span className="detail-label">
                          Total Rounds Fired:
                        </span>
                        <span className="detail-value">
                          {rangeVisits
                            .filter((visit) =>
                              moment(visit.visitDate).isSameOrBefore(
                                moment(),
                                "day"
                              )
                            )
                            .reduce((acc, visit) => {
                              const visitTotal = visit.visitDetail
                                .filter(
                                  (detail) => detail.firearm === firearmId
                                )
                                .reduce(
                                  (acc, curr) => acc + Number(curr.value),
                                  0
                                );
                              return acc + visitTotal;
                            }, 0)}
                        </span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">
                          Rounds Fired Last Month:
                        </span>
                        <span className="detail-value">
                          {rangeVisits
                            .filter(
                              (visit) =>
                                moment(visit.visitDate).isAfter(
                                  moment().subtract(1, "months")
                                ) &&
                                moment(visit.visitDate).isSameOrBefore(
                                  moment(),
                                  "day"
                                )
                            )
                            .reduce((acc, visit) => {
                              const visitTotal = visit.visitDetail
                                .filter(
                                  (detail) => detail.firearm === firearmId
                                )
                                .reduce(
                                  (acc, curr) => acc + Number(curr.value),
                                  0
                                );
                              return acc + visitTotal;
                            }, 0)}
                        </span>
                      </div>
                    </div>
                    <div className="firearm-detail-content-right">
                      <ul>
                        {rangeVisits
                          .sort(
                            (a, b) =>
                              new Date(b.visitDate) - new Date(a.visitDate)
                          ) // Sorting by descending order
                          .map((visit) => {
                            // Calculate the sum of values where the firearm matches the given firearmId
                            const sumValues = visit.visitDetail
                              .filter((detail) => detail.firearm === firearmId)
                              .reduce(
                                (acc, curr) => acc + Number(curr.value),
                                0
                              );

                            return (
                              <li key={visit._id}>
                                <Link
                                  to={`/rangevisit/${visit._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  Date:{" "}
                                  {moment(visit.visitDate).format("YYYY-MM-DD")}
                                  , Total Rounds: {sumValues}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <p>No range visits recorded.</p>
                )}
              </div>
            </div>
            <div className="firearm-detail-section">
              <h2 className="firearm-detail-title">Modifications</h2>
              <div className="firearm-detail-history-box">
                {modifications.length ? (
                  <div className="firearm-detail-content-flex">
                    <div className="firearm-detail-content-left">
                      {/* Future content or additional details can go here */}
                      <div className="detail-section">
                        <span className="detail-label">Total MISC Costs:</span>

                        <span className="firearm-detail-rounds-highlight">
                          $
                          {modifications.reduce(
                            (acc, modification) =>
                              acc + Number(modification.modificationCost),
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="firearm-detail-content-right">
                      {modifications
                        .sort(
                          (a, b) =>
                            new Date(b.modificationDate) -
                            new Date(a.modificationDate)
                        )
                        .map((item, index) => (
                          <li key={index}>
                            Date:{" "}
                            {moment(item.modificationDate).format("YYYY-MM-DD")}{" "}
                            ${item.modificationCost}
                            <br /> {/* Line break between date and note */}
                            {item.modificationNote}
                          </li>
                        ))}
                    </div>
                  </div>
                ) : (
                  <p>No modification recorded.</p>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="firearm-detail-section">
              <h2 className="firearm-detail-title">Gallery</h2>
              <div className="firearm-detail-gallery-grid">
                {/* Display images from the gallery list */}
                {firearm.firearmGallery &&
                  firearm.firearmGallery.map((imageUrl, index) => (
                    <div
                      className="firearm-detail-gallery-item"
                      key={index}
                      onClick={() => handleImageGalleryClick(imageUrl)}
                    >
                      <img key={index} src={imageUrl} />
                    </div>
                  ))}
                <div
                  className="firearm-detail-gallery-item firearm-detail-gallery-add-image-button"
                  onClick={handleAddImageClick}
                >
                  <span>+</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
};

export const Firearm_Detail_Page = {
  path: "/firearm/:firearmId/dashboard",
  element: <FirearmDetails />,
};
