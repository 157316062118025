import React, { useState, useEffect, useContext } from "react";
import "../../common/style.css";
import "./Subscription.css";
import { useNavigate } from "react-router-dom";
import UserInfoContext from "../../UserInfoContext";
function monthDifference(startDate, endDate) {
  let months;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
}

function findClaimValue(claims, claimType) {
  const claim = claims.find((c) => c.typ === claimType);
  return claim ? claim.val : null;
}

function App() {
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  const today = new Date();
  const priceIdToPlanMapping = {
    price_1PqOKJG2pGmOBvxKOhyppYjm: { type: "Starter", frequency: "Annual" },
    price_1PqOJuG2pGmOBvxKFdsCJPdw: { type: "Starter", frequency: "Monthly" },
    price_1POA8aG2pGmOBvxKRVMKlGtP: { type: "Basic", frequency: "Annual" },
    price_1POA5nG2pGmOBvxKPMqH6r82: { type: "Basic", frequency: "Monthly" },
    price_1POAILG2pGmOBvxKZgoXb8I3: { type: "Standard", frequency: "Annual" },
    price_1POAGtG2pGmOBvxKKp95TCdj: { type: "Standard", frequency: "Monthly" },
    price_1POAQyG2pGmOBvxKi1VHeAtI: { type: "Pro", frequency: "Annual" },
    price_1POAQ2G2pGmOBvxKPtdVD2bU: { type: "Pro", frequency: "Monthly" },
    price_1POAeTG2pGmOBvxKTaDboKue: { type: "Unlimited", frequency: "Annual" },
    price_1POAW3G2pGmOBvxKDY7BnE5b: { type: "Unlimited", frequency: "Monthly" },
  };
  const paymentUrls = {
    StarterAnnual: "https://buy.stripe.com/dR6bMr0r89D56AgfZ8",
    StarterMonthly: "https://buy.stripe.com/5kAdUz7TA7uX3o4cMV",
    BasicAnnual: "https://buy.stripe.com/8wM9Ejfm23eHbUA6oo",
    BasicMonthly: "https://buy.stripe.com/6oE9Ejb5M4iLbUA4gh",
    StandardAnnual: "https://buy.stripe.com/4gwdUz3Dk7uX5wc28a",
    StandardMonthly: "https://buy.stripe.com/5kA17N1vcdTl7EkdQT",
    ProAnnual: "https://buy.stripe.com/fZedUz0r84iL1fWaEI",
    ProMonthly: "https://buy.stripe.com/00gdUzfm2aH98IodQV",
    UnlimitedAnnual: "https://buy.stripe.com/dR65o3a1I6qTgaQ7sA",
    UnlimitedMonthly: "https://buy.stripe.com/7sI17Nc9Q7uX0bS6ov",
  };
  const subscriptionPlans = [
    {
      name: "Starter",
      descriptions: ["Maximum firearms: 5", "No activity records"],
      paymentTerms: [
        { type: "Monthly", price: "$2.99/month" },
        { type: "Annual", price: "$20.99/year" },
      ],
    },
    {
      name: "Basic",
      descriptions: ["Maximum firearms: 10", "Unlimited activity records"],
      paymentTerms: [
        { type: "Monthly", price: "$4.99/month" },
        { type: "Annual", price: "$54.99/year" },
      ],
    },
    {
      name: "Standard",
      descriptions: ["Maximum firearms: 50", "Unlimited activity records"],
      paymentTerms: [
        { type: "Monthly", price: "$9.99/month" },
        { type: "Annual", price: "$99.99/year" },
      ],
    },
    {
      name: "Pro",
      descriptions: ["Maximum firearms: 200", "Unlimited activity records"],
      paymentTerms: [
        { type: "Monthly", price: "$17.99/month" },
        { type: "Annual", price: "$189.99/year" },
      ],
    },
    {
      name: "Unlimited",
      descriptions: ["Unlimited firearms", "Unlimited activity records"],
      paymentTerms: [
        { type: "Monthly", price: "$39.99/month" },
        { type: "Annual", price: "$429.99/year" },
      ],
    },
  ];
  const getClass = (planType) => {
    switch (planType) {
      case "Starter":
        return "starter";
      case "Basic":
        return "basic";
      case "Pro":
        return "pro";
      case "Standard":
        return "standard";
      case "Unlimited":
        return "unlimited";
      case "Free":
        return "free";
      default:
        return "";
    }
  };
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const [userEmailAddress, setUserEmail] = useState(null);

  const navigateToPayment = (planType) => {
    const url = paymentUrls[planType];
    console.log(planType);
    if (url) {
      // Redirects the user to the payment URL
      window.location.href = url;
      console.log(`Navigating to payment page for: ${planType}`);
    } else {
      console.log(`No payment link found for: ${planType}`);
      // Handle the case where no URL is found (optional)
      alert("Payment link not available for the selected plan.");
    }
  };
  useEffect(() => {
    async function fetchStripeKey() {
      try {
        const userInfo = await fetchUserInfo();
        const stripe = await fetchStripe();
        setUserEmail(userInfo.email);
        const customerSubscriptions = await fetchSubscriptions(
          userInfo.email,
          stripe
        );
        console.log("Subscriptions for customer:", customerSubscriptions);
        setSubscriptions(customerSubscriptions);
        setLoading(false);
        setShowLoading(false); // Show loading indicator
      } catch (err) {
        console.error("Error in processing:", err);
        setError(err);
        setLoading(false);
        setShowLoading(false); // Show loading indicator
      }
    }

    fetchStripeKey();
  }, []);

  async function fetchUserInfo() {
    const response = await fetch("/.auth/me");
    const payload = await response.json();
    const { clientPrincipal } = payload;
    const userEmail = findClaimValue(clientPrincipal.claims, "emails");
    console.log(userEmail);
    return { email: userEmail };
  }

  async function fetchStripe() {
    const response = await fetch("/api/stripekey");
    if (!response.ok) throw new Error("Failed to fetch the Stripe key");
    const data = await response.json();
    const stripe = require("stripe")(data.key);
    return stripe;
  }

  const planPriority = {
    Unlimited: 6,
    Pro: 5,
    Standard: 4,
    Basic: 3,
    Starter: 2,
    Free: 1,
  };

  async function fetchSubscriptions(userEmail, stripe) {
    try {
      // Search for customer by email
      const customers = await stripe.customers.search({
        query: `email:'${userEmail}'`,
      });

      // Check if the customer is found
      if (customers.data.length === 0) {
        // No customer found for the given email
        console.log("No customer found for this email:", userEmail);
        return [];
      }

      // Fetch all active subscriptions
      const allSubscriptions = await stripe.subscriptions.search({
        query: "status:'active' OR status:'canceled'",
      });

      const customerSubscriptions = [];

      // Loop through each customer and filter their subscriptions
      customers.data.forEach((customer) => {
        const currentCustomerSubscriptions = allSubscriptions.data.filter(
          (subscription) => subscription.customer === customer.id
        );

        // Combine the subscriptions of each customer into one array
        customerSubscriptions.push(...currentCustomerSubscriptions);
      });

      if (customerSubscriptions.length === 0) {
        // No subscriptions found for the customer
        console.log("No active subscriptions found for customer ID:");
        return [];
      }

      // Map and format the subscription details
      return customerSubscriptions.map((subscription) => {
        const startDate = new Date(subscription.current_period_start * 1000);
        const endDate = new Date(subscription.current_period_end * 1000);
        const planDetails = priceIdToPlanMapping[subscription.plan.id] || {};
        const sub_id = subscription.id;
        const status = subscription.status;
        return {
          id: sub_id,
          status: status,
          start_date_readable: startDate.toDateString(),
          end_date_readable: endDate.toDateString(),
          subscription_type: planDetails.frequency || "Unknown Frequency",
          plan_type: planDetails.type || "Unknown Plan",
        };
      });
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      throw new Error("Failed to fetch subscriptions");
    }
  }

  let latestSubscription = subscriptions.reduce(
    (latest, current) => {
      const latestEndDate = new Date(latest.end_date_readable);
      const currentEndDate = new Date(current.end_date_readable);

      // Filter out subscriptions that have already ended
      if (currentEndDate < today) {
        return latest;
      }

      // Priority 1: Active over Canceled
      if (latest.status === "active" && current.status !== "active") {
        return latest;
      } else if (latest.status !== "active" && current.status === "active") {
        return current;
      }

      if (planPriority[latest.plan_type] > planPriority[current.plan_type]) {
        return latest;
      } else if (
        planPriority[latest.plan_type] < planPriority[current.plan_type]
      ) {
        return current;
      }

      // Priority 3: Later end date
      return currentEndDate > latestEndDate ? current : latest;
    },
    {
      plan_type: "Free",
      status: "canceled",
      subscription_type: " ",
    }
  ); // Initialize with the first subscription as the default
  if (latestSubscription) {
    const latestEndDate = new Date(latestSubscription.end_date_readable);
    if (latestEndDate < today) {
      // If the latest subscription's end date is not valid or it's not active, set to null
      latestSubscription = {
        plan_type: "Free",
        status: "canceled",
        subscription_type: " ",
      };
    }
  }

  const updateSubscription = async (email, latestSubscription) => {
    try {
      const response = await fetch("/api/updateSubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, latestSubscription }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Subscription updated successfully:", data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };

  useEffect(() => {
    // Ensure both values are truthy before proceeding
    if (userEmailAddress && latestSubscription) {
      updateSubscription(userEmailAddress, latestSubscription)
        .then(() => {
          console.log("Subscription updated successfully.");
        })
        .catch((error) => {
          console.error("Failed to update subscription:", error);
        });
    }
  }, [userEmailAddress, latestSubscription]); // Dependencies array

  const cancelSubscription = async (subscriptionId) => {
    if (!window.confirm("Are you sure you want to cancel your subscription?")) {
      return; // User canceled the action
    }

    try {
      setShowLoading(true); // Show loading indicator
      console.log("Cancel subscription");
      console.log(subscriptionId);
      const stripe = await fetchStripe();
      const subscription = await stripe.subscriptions.cancel(subscriptionId);

      // Wait for 5 seconds before refreshing the page
      setTimeout(() => {
        window.location.reload(); // Refresh the page
        setShowLoading(false); // Reset loading state
      }, 5000);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert(`Error: ${error.message}`);
      setShowLoading(false); // Hide loading indicator on error
    }
  };
  return (
    <div className="subscription-page-container">
      <div className="page-background subscription-background-image"></div>
      {showLoading && <div className="loading-indicator">Loading...</div>}
      {/* Top section for displaying subscriptions */}
      <div className="top-container">
        {loading && <div className="message-container">Loading...</div>}
        {error && (
          <div className="message-container">Error: {error.message}</div>
        )}
        {!loading && (
          <div className="subscription-status-container">
            <div
              className={`subscription-card ${
                latestSubscription.status === "active" ||
                (userInfo &&
                  latestSubscription.plan_type === "Free" &&
                  userInfo.isBeyond15Days === false)
                  ? "active-subscription"
                  : "canceled-subscription"
              }`}
            >
              {userInfo && userInfo.userRole !== "admin" && (
                <>
                  <h4 className="subscription-title">Subscription Details:</h4>
                  <p>
                    Subscription Type:{" "}
                    <strong className={getClass(latestSubscription.plan_type)}>
                      {latestSubscription.plan_type} (
                      {userInfo &&
                      latestSubscription.plan_type === "Free" &&
                      userInfo.isBeyond15Days === false
                        ? userInfo.daysLeft === 0
                          ? "Your trial has ended."
                          : `Your trial ends in ${userInfo.daysLeft} day${
                              userInfo.daysLeft !== 1 ? "s" : ""
                            }`
                        : latestSubscription.subscription_type}
                      )
                    </strong>
                  </p>
                </>
              )}
              {userInfo && userInfo.userRole === "admin" && (
                <>
                  <h4 className="subscription-title">
                    Hi, you have admin access. Enjoy.
                  </h4>
                </>
              )}

              {latestSubscription &&
                latestSubscription.plan_type !== "Free" && (
                  <>
                    <p>
                      Status:{" "}
                      <strong
                        className={getClass(latestSubscription.plan_type)}
                      >
                        {latestSubscription.status}
                      </strong>
                    </p>
                    <p>
                      Start Date:{" "}
                      <strong
                        className={getClass(latestSubscription.plan_type)}
                      >
                        {latestSubscription.start_date_readable}
                      </strong>
                    </p>
                    {latestSubscription.status === "active" ? (
                      <p>
                        Next Billing Date:{" "}
                        <strong
                          className={getClass(latestSubscription.plan_type)}
                        >
                          {latestSubscription.end_date_readable}
                        </strong>
                      </p>
                    ) : (
                      <p>
                        End Date:{" "}
                        <strong
                          className={getClass(latestSubscription.plan_type)}
                        >
                          {latestSubscription.end_date_readable}
                        </strong>
                      </p>
                    )}
                  </>
                )}

              {latestSubscription.plan_type !== "Free" &&
                latestSubscription.status === "active" && (
                  <button
                    className="cancel-button"
                    onClick={() => cancelSubscription(latestSubscription.id)}
                  >
                    Cancel Subscription
                  </button>
                )}
            </div>
          </div>
        )}
      </div>

      {/* Bottom section for additional options */}
      <div className="subscription-info-container">
        {subscriptionPlans.map((plan, index) => (
          <div
            key={index}
            className={`option-card ${plan.name.toLowerCase()}-plan ${
              latestSubscription && latestSubscription.plan_type === plan.name
                ? "current-plan"
                : ""
            }`}
          >
            <h4 className="plan-title">{plan.name} Plan</h4>
            {plan.descriptions.map((description, index) => (
              <p key={index}>{description}</p>
            ))}
            {latestSubscription.plan_type === "Free" ||
            latestSubscription.status === "canceled"
              ? plan.paymentTerms.length > 0
                ? plan.paymentTerms.map((term, index) => (
                    <button
                      key={index}
                      className="button"
                      onClick={() =>
                        navigateToPayment(`${plan.name}${term.type}`)
                      }
                    >
                      {term.price}
                    </button>
                  ))
                : null
              : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export const Subscription_Page = {
  path: "/subscription",
  element: <App></App>,
};
