import React, { useState } from "react";
import "./AddRangeVisitItem.css";

export default function AddItemForm({ handleSubmit, firearmlist }) {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [firearm, setFirearm] = useState("");
  const [value, setValue] = useState("");
  const [notes, setNotes] = useState("");

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    setFirearm("");
    setValue("");
    setNotes("");
  };

  const handleInternalSubmit = (event) => {
    // event.preventDefault(); // Prevent default if it was a form
    handleSubmit(firearm, value, notes); // Call the passed handleSubmit with current state
    setIsFormVisible(false); // Optionally close form after submit
    setFirearm("");
    setValue("");
    setNotes("");
  };

  return (
    <div className="add-item">
      {/* Toggle button */}
      <button onClick={toggleFormVisibility}>
        {isFormVisible ? "-" : "Add Detail"}
      </button>

      {/* Floating form-container */}
      {isFormVisible && (
        <form
          id="firearm-form"
          onSubmit={handleInternalSubmit}
          encType="multipart/form-data"
          className="form-container"
        >
          <select
            required
            value={firearm}
            onChange={(e) => setFirearm(e.target.value)}
          >
            <option value="">Select Firearm</option>
            {firearmlist.map((firearm) => (
              <option key={firearm._id} value={firearm._id} className="color7">
                {firearm.firearmName}
              </option>
            ))}
          </select>

          <input
            type="number"
            placeholder="Enter value (rounds)"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            required
          />
          <textarea
            placeholder="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            required
          ></textarea>
          <div className="form-actions">
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" form="firearm-form">
              Add
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
