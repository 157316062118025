// passcodeUtils.js
const storePasscode = (passcode) => {
  sessionStorage.setItem("userPasscode", passcode);
};

const getPasscode = () => {
  return sessionStorage.getItem("userPasscode");
};

const clearPasscode = () => {
  sessionStorage.removeItem("userPasscode");
};

module.exports = {
  storePasscode,
  getPasscode,
  clearPasscode,
};
