import "../../common/style.css";
import "./Calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { dateFnsLocalizer } from "react-big-calendar";
import getDay from "date-fns/getDay";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchRangeVisits() {
      try {
        const response = await fetch("/api/rangevisits", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            userid: "", // Assuming you have a mechanism to supply the correct user ID
          },
        });
        if (response.ok) {
          const rangeVisits = await response.json();
          const formattedEvents = rangeVisits.map((visit) => ({
            title: visit.rangeInfo.name, // You could have more dynamic titles based on your data
            start: new Date(visit.visitDate),
            end: new Date(visit.visitDate), // Assumes `end` is the same as `start`; adjust if you store an end date or duration
            allDay: false,
            id: visit._id,
          }));
          setEvents(formattedEvents);
        } else {
          throw new Error("Failed to fetch range visits");
        }
      } catch (error) {
        console.error("Error fetching range visits:", error);
      }
    }

    fetchRangeVisits();
  }, []);
  const handleEventClick = (event) => {
    navigate(`/rangevisit/${event.id}`); // Use event.id to navigate
  };
  return (
    <div className="calendar-page-container">
      <div className="page-background home-background-image"></div>
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          events={events}
          views={["month"]} // Only show month view
          style={{ height: "100%", width: "100%", zIndex: 1000 }} // Set height to fill the viewport
          onSelectEvent={handleEventClick}
        />
      </div>
    </div>
  );
}

export const Calendar_Page = {
  path: "/calendar",
  element: <App></App>,
};
