import React, { useState } from "react";
import "./PasscodePrompt.css";
const PasscodePrompt = ({ onPasscodeSubmit }) => {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");

  const validatePasscode = (passcode) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(passcode);
    const hasLowercase = /[a-z]/.test(passcode);
    const hasNumber = /[0-9]/.test(passcode);
    const hasSpecialChar = /[!@#$%^&*]/.test(passcode);

    if (passcode.length < minLength)
      return `Passcode must be at least ${minLength} characters long.`;
    if (!hasUppercase)
      return "Passcode must include at least one uppercase letter.";
    if (!hasLowercase)
      return "Passcode must include at least one lowercase letter.";
    if (!hasNumber) return "Passcode must include at least one number.";
    if (!hasSpecialChar)
      return "Passcode must include at least one special character.";

    return "";
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const validationError = validatePasscode(passcode);
    if (validationError) {
      setError(validationError);
    } else {
      console.log("here in the passcodeprompt.js");
      setError("");
      console.log("Passcode submitted:", passcode);
      if (onPasscodeSubmit) {
        console.log("onPasscodeSubmit exists"); // Debugging line
        onPasscodeSubmit(passcode);
      } else {
        console.log("onPasscodeSubmit does not exist"); // Debugging line
      }
    }
  }

  const handleLogout = (e) => {
    e.preventDefault();
    window.location.href = "/.auth/logout";
  };

  return (
    <div className="aes-overlay">
      <div className="aes-form-container">
        <div className="aes-instructions">
          <p>
            <strong>Why you need a passcode:</strong> To secure your sensitive
            data, we require you to set a passcode that will be used to encrypt
            your information. This ensures that only you can access your data.
          </p>
          <p>
            <strong>Passcode requirements:</strong> Your passcode should be at
            least 8 characters long and include a mix of uppercase letters,
            lowercase letters, numbers, and special characters.
          </p>
          <p>
            <strong>Important:</strong> Please write down your passcode and keep
            it in a safe place. If you lose your passcode, it cannot be
            recovered and you will lose access to your encrypted data.
          </p>
          <p>
            <strong>Join our Discord for support:</strong> If you need help or
            have questions, you are welcome to join our
            <a
              href="https://discord.gg/WvtcH9Hwhe"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Discord community
            </a>{" "}
            and get 24/7 support. Alternatively, you can also reach out to us
            via email at{" "}
            <a href="mailto:support@armorysync.com">support@armorysync.com</a>.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="aes-form">
          <label>
            Enter Passcode:
            <input
              type="password"
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              required
            />
          </label>
          {error && <p className="aes-error">{error}</p>}
          <button type="submit">Submit</button>
          <button
            type="button"
            onClick={handleLogout}
            className="logout-button-link"
          >
            Log out
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasscodePrompt;
