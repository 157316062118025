import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import { Link } from "react-router-dom";
import "./FirearmCard.css";

const FirearmCardList = ({ firearm, onDelete, displayOnly = false }) => {
  return (
    <Link
      to={`/firearm/${firearm._id}/dashboard`}
      className="firearms-list-item-container nodecoration"
    >
      <div className="firearms-list-item">
        <div>{firearm.firearmName}</div>
        <div>{firearm.firearmType}</div>
        <div>{firearm.firearmMake}</div>
        <div>{firearm.firearmModel}</div>
        <div>{firearm.firearmCaliber}</div>
        <div>{firearm.firearmPrice}</div>
        <div>{firearm.firearmAcquisitionMethod}</div>
        <div>
          {new Date(firearm.firearmAcquisitionDate).toISOString().split("T")[0]}
        </div>
      </div>
    </Link>
  );
};

export default FirearmCardList;
