import "../../common/style.css";
import "./Home.css";

import FirearmCard from "../../common/FirearmCard/FirearmCard";
import MapImageSummary from "../../common/MapImageSummary/MapImageSummary";
import LastMonthChart from "../../common/LastMonthChart/LastMonthChart";
import PasscodePrompt from "../../common/AESEncrypt/PasscodePrompt";
import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getFirearms } from "../../common/Data/dataUtils";

import "intro.js/introjs.css";
import { Steps } from "intro.js-react";

import UserInfoContext from "../../UserInfoContext";
function App() {
  const steps = [
    {
      element: "#InventorySummary",
      intro:
        "Here you can see how many firearms you currently own, click to access the inventory page",
    },
    {
      element: "#firearmRollingWindow",
      intro:
        "All of your firearms will be listed here. For each one, you can click to access the detail page",
    },
    {
      element: "#rangeVisitMap",
      intro:
        "On the map, all of your previous range visit location will be placed. Click to access the range visit page",
    },
    {
      element: "#lastMonthRangeVisit",
      intro:
        "The histogram shows your last month's range visit with how many rounds you shot for each range visit. For each dot, you can click and access that specific range visit detail page",
    },
    {
      element: "#futureRangeVisit",
      intro:
        "All your planned future range visit will be displayed here. You can click each one to access the detail page",
    },
  ];
  const navigate = useNavigate();
  const [firearms, setFirearms] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const [rangevisits, setRangevisits] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [nextVisit, setNextVisit] = useState([]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  useEffect(() => {
    const checkPasscode = () => {
      const storedPasscode = sessionStorage.getItem("userPasscode");
      console.log("check the passcode");
      console.log(storedPasscode);
      if (storedPasscode) {
        setIsAuthenticated(true);
        fetchFirearms();
      }
    };
    if (!isAuthenticated) {
      const intervalId = setInterval(checkPasscode, 50); // Check every second

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !firearms) {
      setShowLoading(true);
      const intervalId = setInterval(async () => {
        console.log("Attempting to fetch firearms...");
        const storedFirearms = getFirearms("firearms");
        if (storedFirearms) {
          setFirearms(storedFirearms);
          console.log("Fetched firearms:", storedFirearms);
          setShowLoading(false);
          clearInterval(intervalId); // Stop the interval once data is fetched
        }
      }, 100); // Try fetching every second

      return () => clearInterval(intervalId); // Clean up the interval on component unmount or when firearms are fetched
    }
  }, [isAuthenticated, firearms]);

  const fetchFirearms = async () => {
    const storedFirearms = getFirearms("firearms");
    console.log(storedFirearms);
    setFirearms(storedFirearms);
    setShowLoading(false);
  };

  const fetchRangeVisits = async () => {
    const response = await fetch("/api/rangevisits");
    const data = await response.json();
    console.log(data);
    setRangevisits(data);
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchRangeVisits();
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current && firearms.length > 0) {
      scrollContainerRef.current.scrollLeft = -200 * firearms.length; // Ensure it starts from the leftmost position
    }
  }, [firearms]);

  useEffect(() => {
    console.log(rangevisits);
    const cordList = rangevisits.map((visit) => ({
      lat: visit.rangeLat,
      lng: visit.rangeLng,
    }));
    setCoordinates(cordList);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset the time to midnight for comparison

    // Filter and sort range visits
    const upcomingVisits = rangevisits
      .filter((visit) => {
        // Parse visit date and check if it's after today's date
        const visitDate = new Date(visit.visitDate);
        return visitDate >= today; // Only future dates
      })
      .sort((a, b) => {
        // Sort by date (closest to today first)
        const dateA = new Date(a.visitDate);
        const dateB = new Date(b.visitDate);
        return dateA - dateB; // Ascending order (closest first)
      });

    // The first item is the visit closest to today
    const closestVisit = upcomingVisits[0];
    console.log("Next visit");
    console.log(closestVisit);
    setNextVisit(closestVisit);
  }, [rangevisits]);

  // Auto-scroll interval logic with looping effect
  useEffect(() => {
    let scrollInterval;

    // Function to automatically scroll
    const autoScroll = () => {
      if (scrollContainerRef.current && !isMouseOver) {
        const container = scrollContainerRef.current;
        container.scrollLeft += 1; // Adjust the scroll speed as needed

        // If at or near the far right, reset to the left
        if (
          container.scrollLeft * 2 >=
          container.scrollWidth - container.clientWidth
        ) {
          container.scrollLeft = (-container.clientWidth / 2) * firearms.length; // Reset to the start
        }
      }
    };

    // Set up a repeating interval
    scrollInterval = setInterval(autoScroll, 30); // Adjust the interval speed (ms) as needed

    // Clear interval on component unmount
    return () => clearInterval(scrollInterval);
  }, [isMouseOver]);

  // Event handlers for mouse enter/leave
  const handleMouseEnter = () => setIsMouseOver(true);
  const handleMouseLeave = () => setIsMouseOver(false);

  const handleContainerClick = () => {
    navigate("/rangevisit/dashboard"); // Navigate to the "rangevisit/add" route
  };

  return (
    <div className="page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      <div className="page-background home-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isAuthenticated && firearms ? (
        <>
          <div className="page-left-container">
            {/* Top section of the left side */}
            <div className="home-left-top">
              <Link
                to="/firearm/list"
                className="inventory-link"
                id="InventorySummary"
              >
                {`You have ${firearms.length} firearm${
                  firearms.length === 1 ? "" : "s"
                } in your inventory`}
              </Link>
            </div>

            {/* Bottom section of the left side */}
            <div
              className="home-left-bottom"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className="horizontal-scroll-container"
                id="firearmRollingWindow"
                ref={scrollContainerRef}
              >
                {firearms.map((firearm, index) => (
                  <div
                    key={`${firearm._id}-${index}`}
                    className="firearm-wrapper"
                  >
                    <FirearmCard firearm={firearm} displayOnly={true} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="page-right-container">
            {/* Top Section */}
            <div className="home-right-top">
              <div
                className="home-map-container"
                id="rangeVisitMap"
                onClick={handleContainerClick}
              >
                <MapImageSummary
                  coordinates={coordinates}
                  className="home-map-image"
                />{" "}
                {/* Updated map class */}
              </div>
              <div className="home-chart-container">
                <h2>Last Month's Visits</h2>
                <div className="chart-responsive" id="lastMonthRangeVisit">
                  <LastMonthChart rangevisits={rangevisits} />
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="home-right-bottom" id="futureRangeVisit">
              {nextVisit && nextVisit.rangeInfo && nextVisit.rangeInfo.name ? (
                <Link
                  to={`/rangevisit/${nextVisit._id}`}
                  className="home-next-visit-link"
                >
                  <div>
                    {/* Display the upcoming range visit date */}
                    <p>
                      Upcoming Range Visit:{" "}
                      {moment(nextVisit.visitDate).format("YYYY-MM-DD")}
                      {" at "}
                      {nextVisit.rangeInfo.name}
                    </p>

                    {/* Check if there are visit details and display them */}
                    {nextVisit.visitDetail &&
                      nextVisit.visitDetail.length > 0 && (
                        <div className="home-detail-list">
                          {firearms &&
                            nextVisit.visitDetail.map((detail, index) => {
                              const firearm = firearms.find(
                                (f) => f._id === detail.firearm
                              );

                              // Check if the firearm object is defined before rendering the link
                              if (firearm) {
                                return (
                                  <Link
                                    to={`/firearm/${firearm._id}/dashboard`}
                                    className="home-next-visit-link"
                                    key={detail.id}
                                  >
                                    <div className="detail-item">
                                      <span className="firearm">
                                        {firearm.firearmName}
                                      </span>
                                      <span className="value">
                                        {detail.value} rounds
                                      </span>
                                    </div>
                                  </Link>
                                );
                              } else {
                                // Handle missing firearm cases (optional: Customize this message as needed)
                                return (
                                  <div key={detail.id} className="detail-item">
                                    <span className="firearm">
                                      Unknown Firearm
                                    </span>
                                    <span className="value">
                                      {detail.value} rounds
                                    </span>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      )}
                  </div>
                </Link>
              ) : (
                // Display message if there is no upcoming visit
                <p>Plan your next visit now!</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Home_Page = {
  path: "/home",
  element: <App></App>,
};
