import React, { useEffect, useRef, useState, useMemo } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { useCombobox } from "downshift";
import "./GoogleMapSearch.css";

export default function SearchBox({ lat, lng, onRangeInfo }) {
  const [mapObject, setMapObject] = useState(null); // State to hold the map object
  const mapRef = useRef(null);
  const [apiKey, setApiKey] = useState("");
  const [googleReady, setGoogleReady] = useState(false);
  const [placeID, setPlaceId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchResult, setSearchResult] = useState({
    autocompleteSuggestions: [],
    status: "",
  });
  const marker = useRef(); // ADDED
  const [rangeInfo, setRangeInfo] = useState(null);
  // Fetch the API key from the server
  useEffect(() => {
    async function fetchApiKey() {
      try {
        const response = await fetch("/api/googlemapkey");
        const data = await response.json();
        if (data && data.key) {
          setApiKey(data.key); // Set the API key if fetched successfully
        }
      } catch (error) {
        console.error("Error fetching the Google Maps API key:", error);
      }
    }

    fetchApiKey();
  }, []);

  // Load and initialize the Google Map
  useEffect(() => {
    if (apiKey) {
      const loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
        libraries: ["places"],
      });

      loader
        .load()
        .then(() => {
          setGoogleReady(true); // Set a flag when Google is ready
          const center = new window.google.maps.LatLng(lat, lng);
          const map = new window.google.maps.Map(mapRef.current, {
            center: center,
            zoom: 11,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false, // Disables the Street View control
            rotateControl: false, // Disables the rotation control for 45° imagery
          });
          setMapObject(map);
          new window.google.maps.Marker({
            position: center,
            map: map,
            title: "Current Location",
          });
        })
        .catch((error) => {
          console.error("Failed to load Google Maps", error);
        });
    }
  }, [apiKey, lat, lng]);

  useEffect(() => {
    if (marker.current) {
      // ADDED
      marker.current.setMap(null); // ADDED
    }
    if (placeID && mapObject && window.google) {
      const service = new window.google.maps.places.PlacesService(mapObject);
      const request = {
        placeId: placeID,
        fields: ["formatted_address", "geometry", "name", "url"],
      };
      service.getDetails(request, handleResponse);

      function handleResponse(place, placesServiceStatus) {
        if (
          placesServiceStatus ===
          window.google.maps.places.PlacesServiceStatus.OK
        ) {
          const searchedPlace = {
            address: place.formatted_address,
            coordinates: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            name: place.name,
            url: place.url,
          };
          console.log("Place details:", searchedPlace);
          setRangeInfo(searchedPlace);
          onRangeInfo(searchedPlace);
          marker.current = new window.google.maps.Marker({
            position: searchedPlace.coordinates,
            title: searchedPlace.name,
            icon: {
              url: "/rangeposition.png",
              scaledSize: new window.google.maps.Size(40, 40), // Controls the size of the icon
            },
          });
          window.google.maps.event.addListener(
            marker.current,
            "click",
            function () {
              window.open(searchedPlace.url, "_blank");
            }
          );
          marker.current.setMap(mapObject);
          mapObject.panTo(searchedPlace.coordinates); // ADDED
        } else {
          console.error("Failed to fetch place details:", placesServiceStatus);
        }
      }
    }
  }, [mapObject, placeID]);

  // Memoize the AutocompleteSessionToken
  const sessionToken = useMemo(() => {
    if (googleReady) {
      // Ensure Google Maps is loaded
      return new window.google.maps.places.AutocompleteSessionToken();
    }
    return null;
  }, [googleReady]);

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    highlightedIndex,
    selectedItem: downshiftSelectedItem,
  } = useCombobox({
    items: searchResult.autocompleteSuggestions,
    itemToString: (item) => (item ? item.name : ""), // Ensures item is converted to a string properly
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
      if (!inputValue) {
        setSearchResult({ autocompleteSuggestions: [], status: "" });
        return;
      }

      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: inputValue,
          sessionToken: sessionToken,
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const autocompleteSuggestions = predictions.map((prediction) => ({
              id: prediction.place_id,
              name: prediction.structured_formatting.main_text,
              address: prediction.structured_formatting.secondary_text,
            }));
            setSearchResult({ autocompleteSuggestions, status: "OK" });
          } else {
            setSearchResult({ autocompleteSuggestions: [], status });
          }
        }
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      console.log(selectedItem);
      setInputValue(selectedItem.name); // Update the input to show the name of the selected item
    },
    inputValue: inputValue, // Controlled input value
  });

  return (
    <div className="search-box-container">
      <input
        {...getInputProps()}
        type="search"
        placeholder="Search places"
        className="search-input"
      />
      <ul
        {...getMenuProps({
          style: {
            position: "absolute",
            top: 40, // Positions the dropdown right below the input box
            left: "10px",
            width: "calc(100% - 20px)",
            right: 0,
            zIndex: 1000, // Ensure it's above other content
            backgroundColor: "white",
            color: "black",
            border: "1px solid #ccc",
            maxHeight: "300px", // Optional: Set a max height for the dropdown
            overflowY: "auto", // Optional: Add scroll to the dropdown
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Optional: Adds subtle shadow to dropdown
          },
        })}
      >
        {isOpen &&
          searchResult.autocompleteSuggestions.map((item, index) => (
            <li
              key={item.id}
              {...getItemProps({
                item,
                index,
                style: {
                  backgroundColor:
                    highlightedIndex === index ? "#bde4ff" : "transparent",
                  padding: "10px", // Provides padding inside each dropdown item
                  cursor: "pointer", // Changes the cursor to pointer to indicate it's clickable
                },
                onClick: (event) => {
                  setPlaceId(item.id);
                },
              })}
              className="dropdown-item"
            >
              {item.name} ({item.address})
            </li>
          ))}
      </ul>
      <div ref={mapRef} className="map" />
    </div>
  );
}
