import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UserInfoProvider from "./UserInfoProvider";
import { Home_Page } from "./routes/Home/Home";

import { Calendar_Page } from "./routes/Calendar/Calendar";

import { Contact_Page } from "./routes/Contact/Contact";
import { WhatsNew_Page } from "./routes/WhatsNew/WhatsNew";
import { Faq_Page } from "./routes/FAQ/FAQ";

import { Rangevisit_Dashboard } from "./routes/RangeVisit/RangeVisitDashboard/RangeVisitDashboard";
import { Rangevisit_Detail } from "./routes/RangeVisit/RangeVisitDetail/RangeVisitDetail";

import { Maintenance_List } from "./routes/Maintenance/MaintenanceList/MaintenanceList";
import { Modification_List } from "./routes/Modification/ModificationList/ModificationList";
import { Accessory_List } from "./routes/Inventory/AccessoryList/AccessoryList";
import { Ammo_List } from "./routes/Inventory/AmmoList/AmmoList";
import { Ammo_Detail_Page } from "./routes/Inventory/AmmoDetail/AmmoDetail";

import { FirearmList_Page } from "./routes/Inventory/FirearmList/FirearmList";
import { Firearm_Detail_Page } from "./routes/Inventory/FirearmDetail/FirearmDetail";

import { Subscription_Page } from "./routes/Subscription/Subscription";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      Home_Page,
      Rangevisit_Dashboard,
      Rangevisit_Detail,
      FirearmList_Page,
      Firearm_Detail_Page,
      Calendar_Page,
      Subscription_Page,
      Maintenance_List,
      Modification_List,
      Accessory_List,
      Ammo_List,
      Ammo_Detail_Page,
      Contact_Page,
      WhatsNew_Page,
      Faq_Page,
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserInfoProvider>
      <RouterProvider router={router} />
    </UserInfoProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
