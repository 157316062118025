import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import "./AccessoryListItem.css";

const AccessoryListItem = ({ accessory, firearm, onClick }) => {
  return (
    <div className="accessory-list-item" onClick={() => onClick(accessory)}>
      <div>
        {
          new Date(accessory.accessoryAcquisitionDate)
            .toISOString()
            .split("T")[0]
        }
      </div>
      <div>{accessory.accessoryAcquisitionMethod}</div>
      <div>{accessory.accessoryAcquisitionSource}</div>
      <div>{accessory.accessoryAcquisitionPrice}</div>
      <div>{accessory.accessoryName}</div>
      <div>{accessory.accessoryNote}</div>
      <div>{firearm}</div>
    </div>
  );
};

export default AccessoryListItem;
