import { Link } from "react-router-dom";
import "./RangeVisitItem.css";
import moment from "moment";
import MapImage from "../MapImage/MapImage";
export default function RangeVisitItem({ visit, onDelete }) {
  return (
    <div className="grid-item">
      <button
        className="delete-button"
        onClick={(e) => {
          e.stopPropagation(); // Correctly preventing link navigation
          onDelete(visit._id);
        }}
        aria-label="Delete visit" // Accessibility improvement
      >
        ✕
      </button>
      <Link to={`/rangevisit/${visit._id}`} className="grid-item-link">
        <div className="range-visit-map-container">
          <MapImage lat={visit.rangeLat} lng={visit.rangeLng} />
        </div>
        <p>{visit.rangeInfo.name}</p>
        <p>{moment(visit.visitDate).format("YYYY-MM-DD")}</p>
        <p>{visit.duration} min</p>
      </Link>
    </div>
  );
}
