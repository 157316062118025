const crypto = require("crypto-browserify");

// Generate random bytes using Web Crypto API
const getRandomBytes = (length) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Buffer.from(array);
};

const deriveKey = (passcode, salt) => {
  return crypto.pbkdf2Sync(passcode, salt, 100000, 32, "sha256");
};

const encryptData = (data, key) => {
  const iv = getRandomBytes(16); // Use getRandomBytes instead of crypto.randomBytes
  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  let encrypted = cipher.update(data, "utf8", "hex");
  encrypted += cipher.final("hex");
  return { iv: iv.toString("hex"), encryptedData: encrypted };
};

const decryptData = (encryptedData, key, iv) => {
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    key,
    Buffer.from(iv, "hex")
  );
  let decrypted = decipher.update(encryptedData, "hex", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
};

const encryptField = (field, key) => {
  const { iv, encryptedData } = encryptData(field, key);
  return { iv, encryptedData };
};

const decryptField = (field, key, iv) => {
  return decryptData(field, key, iv);
};

const encryptObject = (obj, passcode) => {
  const salt = getRandomBytes(16);
  const key = crypto.pbkdf2Sync(passcode, salt, 100000, 32, "sha256");
  const iv = getRandomBytes(16);
  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  let encrypted = cipher.update(JSON.stringify(obj), "utf8", "hex");
  encrypted += cipher.final("hex");
  return {
    salt: salt.toString("hex"),
    iv: iv.toString("hex"),
    encryptedData: encrypted,
  };
};

const decryptObject = (encrypted, passcode) => {
  const salt = Buffer.from(encrypted.salt, "hex"); // Retrieve the salt from the encrypted data
  const key = crypto.pbkdf2Sync(passcode, salt, 100000, 32, "sha256");
  const iv = Buffer.from(encrypted.iv, "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  let decrypted = decipher.update(encrypted.encryptedData, "hex", "utf8");
  decrypted += decipher.final("utf8");
  return JSON.parse(decrypted);
};

module.exports = {
  getRandomBytes,
  deriveKey,
  encryptData,
  decryptData,
  encryptField,
  decryptField,
  encryptObject,
  decryptObject,
};
