import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./FirearmCard.css";

const FirearmCard = ({ firearm, onUpdate, onDelete, displayOnly = false }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedFirearm, setEditedFirearm] = useState({ ...firearm });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFirearm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleOnClick = () => {
    if (editMode) {
      return;
    }

    navigate(`/firearm/${firearm._id}/dashboard`);
  };

  const saveChanges = () => {
    console.log(editedFirearm);
    onUpdate(editedFirearm);
    setEditMode(false);
  };

  const cancelChanges = () => {
    setEditedFirearm({ ...firearm });
    setEditMode(false);
  };

  return (
    <div
      className="card card-style"
      style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "10px" }}
    >
      <div className="card-image" onClick={handleOnClick}>
        <figure className="image">
          <img
            src={firearm.firearmImage}
            alt={`Firearm ${firearm.firearmName}`}
            className="firearm-image"
          />
        </figure>
      </div>
      <div className="card-content" onClick={handleOnClick}>
        {editMode ? (
          <div>
            <input
              type="text"
              name="firearmName"
              value={editedFirearm.firearmName}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="text"
              name="firearmType"
              value={editedFirearm.firearmType}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="text"
              name="firearmMake"
              value={editedFirearm.firearmMake}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="text"
              name="firearmModel"
              value={editedFirearm.firearmModel}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="text"
              name="firearmCaliber"
              value={editedFirearm.firearmCaliber}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="text"
              name="firearmPrice"
              value={editedFirearm.firearmPrice}
              onChange={handleInputChange}
              className="editable-field"
            />
            <input
              type="date"
              name="firearmAcquisitionDate"
              value={editedFirearm.firearmAcquisitionDate}
              onChange={handleInputChange}
              className="editable-field"
            />
          </div>
        ) : (
          <>
            <Link to={`/firearm/${firearm._id}`} className="nodecoration">
              <p className="title is-4">{firearm.firearmName}</p>
              <p className="subtitle is-6">
                Type: {firearm.firearmType}
                <br />
                Make: {firearm.firearmMake}
                <br />
                Model: {firearm.firearmModel}
                <br />
                Caliber: {firearm.firearmCaliber}
                <br />
                Price: ${firearm.firearmPrice}
                <br />
                Acquisition Date:{" "}
                {
                  new Date(firearm.firearmAcquisitionDate)
                    .toISOString()
                    .split("T")[0]
                }
              </p>
            </Link>
          </>
        )}
      </div>
      {!displayOnly &&
        (editMode ? (
          <div className="card-footer">
            <button
              className="card-footer-item button firearm-item-save-button"
              onClick={saveChanges}
            >
              Save
            </button>
            <button
              className="card-footer-item button firearm-item-cancel-button"
              onClick={cancelChanges}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="card-footer">
            <button
              className="card-footer-item button firearm-item-edit-button"
              onClick={() => setEditMode(true)}
            >
              Edit
            </button>
            <button
              className="card-footer-item button firearm-item-delete-button"
              onClick={() => onDelete(firearm)}
            >
              Delete
            </button>
          </div>
        ))}
    </div>
  );
};

export default FirearmCard;
