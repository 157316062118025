import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "bulma/css/bulma.min.css";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./AmmoDetail.css"; // Ensure you import your custom styles here
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ImageModal } from "../../../common/Image/ImageModal";
import AddAmmoPurchaseForm from "../../../common/AddAmmoPurchaseItem/AddAmmoPurchaseItem";
import moment from "moment";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
const AmmoDetails = () => {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    let tempMessage = "";
    if (userInfo) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#ammoDetailInfo",
      intro:
        "Here you will find all the info for this category of ammo. Click the button on the bottom to edit or delete the ammo. Click the images to zoom in and switch the image.",
    },
    {
      element: "#acquisitionHistory",
      intro:
        "The ammo-associated acquisition history will be displayed here. Click the top button to add a new acquistion of the ammo. ",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const { ammoId } = useParams();
  const [ammo, setAmmo] = useState(null);
  const [newAmmo, setNewAmmo] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [imageDisplayImageSrc, setImageDisplayImageSrc] = useState(null);
  const [imageDisplayImageType, setImageDisplayImageType] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isSwitchImage, setIsSwitchImage] = useState(null);
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };
  const fetchAmmo = async () => {
    const response = await fetch("/api/ammo/" + ammoId);
    const data = await response.json();
    console.log(data);
    setAmmo(data);
    setNewAmmo(data);
    setShowLoading(false);
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchAmmo();
  }, []);
  const [acquisitionHistory, setAcquisitionHistory] = useState(null);
  useEffect(() => {
    if (ammo && ammo.ammoAcquisitionHistory) {
      setAcquisitionHistory(ammo.ammoAcquisitionHistory);
    }
  }, [ammo]);

  useEffect(() => {
    console.log("New ammo info edit");
    if (isSwitchImage && isSwitchImage === true) {
      console.log(newAmmo);
      confirmEdit();
      console.log("Ammo image updated and old image deleted successfully.");
      setIsSwitchImage(null);
      setShowLoading(false);
    }
    setImageModalOpen(false);
  }, [newAmmo]);

  async function confirmEdit() {
    const response = await fetch("/api/ammo/" + newAmmo._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newAmmo),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error("Failed to submit ammo data");
    }
    setAmmo(newAmmo);
    setIsEditing(false);
    setShowLoading(false);
  }
  const handleInfoEdit = (event) => {
    const { name, value } = event.target;
    setNewAmmo((prev) => ({ ...prev, [name]: value }));
    console.log(newAmmo);
  };
  const cancelEdit = () => {
    console.log("Cancel update");
    toggleEditMode();
    setNewAmmo(ammo);
  };

  async function deleteAmmo(ammoId, imageUrl) {
    const deleteResponse = await fetch(`/api/ammo/${ammoId}`, {
      method: "DELETE",
    });

    try {
      const parts = imageUrl.split("/");
      const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL

      const imageDeleteResponse = await fetch(
        `/api/delete/cloudflare/url/${imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!imageDeleteResponse.ok) {
        const error = await imageDeleteResponse.json();
        console.log(`Failed to delete image: ${error.message}`);
      } else {
        console.log("Image deleted successfully.");
      }
    } catch {}

    if (deleteResponse.ok) {
      console.log("Ammo deleted successfully.");
      return true;
    } else {
      console.error("Failed to delete the firearm.");
      // Handle failure in deletion
      return false;
    }
  }
  const handleDelete = async () => {
    // Confirm before proceeding with the deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this ammo page? All associated purchasing history will be lost."
    );

    if (isConfirmed) {
      // User clicked OK
      console.log(ammo._id);

      // Proceed with the deletion logic here
      try {
        // Assuming you have a function to call your API to delete the firearm
        setShowLoading(true);
        await deleteAmmo(ammo._id, ammo.ammoImage);
        setShowLoading(false);
        // Navigate to the home page after successful deletion
        navigate("/ammo/list");
      } catch (error) {
        console.error("Failed to delete the ammo:", error);
        setShowLoading(false);
        // Handle errors, maybe show a message to the user
      }
    } else {
      // User clicked Cancel, handle accordingly
      console.log("Deletion canceled.");
    }
  };
  const handleImageClick = (imageUrl, imageType) => {
    setImageDisplayImageSrc(imageUrl);
    setImageDisplayImageType(imageType);
    setImageModalOpen(true);
  };
  const [uploadUrl, setUploadUrl] = useState("");
  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    if (response.uploadURL) {
      setUploadUrl(response.uploadURL);
    }
    return response.uploadURL;
  };
  const handleSwitch = () => {
    const imageTypeMessage =
      imageDisplayImageType === "ammoImage"
        ? "Do you want to switch the ammo page image?"
        : "Do you want to switch the ammo acquisition proof?";

    setIsSwitchImage(true);
    if (window.confirm(imageTypeMessage)) {
      setShowLoading(true);
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/png, image/jpg, image/jpeg";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        console.log("Here");
        if (file) {
          const newImageUplaodUrl = await setCloudFlareUploadURL();
          console.log(newImageUplaodUrl);
          if (!newImageUplaodUrl) {
            console.error("Upload URL is not set.");
            setImageModalOpen(false);
            setShowLoading(false);
            return;
          }

          console.log("Start upload new image");
          // Upload the new image
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await fetch(newImageUplaodUrl, {
            method: "POST",
            body: formData,
          });
          console.log("Upload response ready to check");
          if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            if (
              !data.result ||
              !data.result.variants ||
              data.result.variants.length === 0
            ) {
              setImageModalOpen(false);
              setShowLoading(false);
              throw new Error("No variants found in the upload response.");
            }
            const newImageURL = data.result.variants[0];

            // Delete the original image
            try {
              const parts = imageDisplayImageSrc.split("/");
              const imageId = parts[parts.length - 2]; // Assuming the ID is the second last part of the URL
              console.log(imageId);
              console.log(newImageURL);
              const imageDeleteResponse = await fetch(
                `/api/delete/cloudflare/url/${imageId}`,
                {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              if (!imageDeleteResponse.ok) {
                const error = await imageDeleteResponse.json();
                console.error(`Failed to delete image: ${error.message}`);
              } else {
                console.log("Image deleted successfully.");
              }
            } catch {}

            // Update firearm with new image URL
            setNewAmmo((prev) => ({
              ...prev,
              [imageDisplayImageType]: newImageURL,
            }));
            // Wait a tick to ensure state update initiates
          } else {
            setImageModalOpen(false);
            setShowLoading(false);
            throw new Error("Failed to upload new image.");
          }
        } else {
          setImageModalOpen(false);
          setShowLoading(false);
        }
      };
      fileInput.click();
    }
  };
  async function handleSubmit(value, rounds, notes, acquisitionDate) {
    setShowLoading(true);
    const formattedAcquisitionDate = acquisitionDate.toISOString();
    const newAcquisitionHistory = {
      value: value,
      rounds: rounds,
      notes: notes,
      acquisitionDate: formattedAcquisitionDate,
      id: uuidv4(),
    };
    console.log(newAcquisitionHistory);

    const newAcquisitionHistories = [
      ...acquisitionHistory,
      newAcquisitionHistory,
    ];
    setNewAmmo((prev) => ({
      ...prev,
      ["ammoAcquisitionHistory"]: newAcquisitionHistories,
    }));
    setAmmo((prev) => ({
      ...prev,
      ["ammoAcquisitionHistory"]: newAcquisitionHistories,
    }));
    setAcquisitionHistory(newAcquisitionHistories);
    // // Use the updated array for the PUT request
    const updateAmmo = {
      ammoAcquisitionHistory: newAcquisitionHistories,
    };

    await fetch("/api/ammo/" + ammo._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateAmmo),
    });
    setShowLoading(false);
  }
  async function handleDeleteItem(id) {
    setShowLoading(true);
    // Filter out the item with the given id
    const newAcquisitionHistories = acquisitionHistory.filter(
      (detail) => detail.id !== id
    );
    setNewAmmo((prev) => ({
      ...prev,
      ["ammoAcquisitionHistory"]: newAcquisitionHistories,
    }));
    setAmmo((prev) => ({
      ...prev,
      ["ammoAcquisitionHistory"]: newAcquisitionHistories,
    }));
    setAcquisitionHistory(newAcquisitionHistories);
    const updateAmmo = {
      ammoAcquisitionHistory: newAcquisitionHistories,
    };

    await fetch("/api/ammo/" + ammo._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateAmmo),
    });

    setShowLoading(false);
  }
  return (
    <div className="page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      <div className="page-background ammo-page-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isImageModalOpen && (
        <ImageModal
          src={imageDisplayImageSrc}
          alt={`Ammo ${ammo.ammoName}`}
          onClose={() => setImageModalOpen(false)}
          onSwitch={handleSwitch} // Define this function based on your needs
        />
      )}
      <div className="page-left-container" id="ammoDetailInfo">
        <div className="ammo-detail-box">
          {isEditing && (
            <div className="ammo-detail-card">
              <div className="ammo-detail-field">
                <label className="ammo-detail-label">Ammo Name</label>
                <div className="ammo-detail-control">
                  <input
                    className="ammo-detail-input"
                    type="text"
                    name="ammoName"
                    value={newAmmo.ammoName}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="ammo-detail-field">
                <label className="ammo-detail-label">Ammo Caliber</label>
                <div className="ammo-detail-control">
                  <input
                    className="ammo-detail-input"
                    type="text"
                    name="ammoCaliber"
                    value={newAmmo.ammoCaliber}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
              <div className="ammo-detail-field">
                <label className="ammo-detail-label">Ammo Grain</label>
                <div className="ammo-detail-control">
                  <input
                    className="ammo-detail-input"
                    type="text"
                    name="ammoGrain"
                    value={newAmmo.ammoGrain}
                    onChange={handleInfoEdit}
                  />
                </div>
              </div>
            </div>
          )}
          {!isEditing &&
            (ammo && ammo.ammoAcquisitionHistory ? (
              <div className="ammo-detail-card">
                <div className="ammo-detail-card-content">
                  <div
                    className="ammo-detail-card-image"
                    onClick={() =>
                      handleImageClick(ammo.ammoImage, "ammoImage")
                    }
                  >
                    <figure className="ammo-detail-image is-4by3">
                      <img
                        src={ammo.ammoImage}
                        alt={`Firearm ${ammo.ammoName}`}
                      />
                    </figure>
                  </div>
                  <p className="ammo-detail-title is-4">Ammo {ammo.ammoName}</p>
                  <p className="ammo-detail-subtitle is-6">
                    <span className="ammo-detail-detail-label">Name:</span>{" "}
                    {ammo.ammoName}
                    <br />
                    <span className="ammo-detail-detail-label">
                      Caliber:
                    </span>{" "}
                    {ammo.ammoCaliber}
                    <br />
                    <span className="ammo-detail-detail-label">
                      Grain:
                    </span>{" "}
                    {ammo.ammoGrain}
                    <br />
                    <span className="ammo-detail-detail-label">
                      Total Cost:
                    </span>
                    $
                    {ammo.ammoAcquisitionHistory
                      .reduce(
                        (total, item) => total + parseFloat(item.value),
                        0
                      )
                      .toFixed(2)}
                    <br />
                    <span className="ammo-detail-detail-label">
                      Total Rounds:
                    </span>
                    {ammo.ammoAcquisitionHistory.reduce(
                      (total, item) => total + parseInt(item.rounds, 10),
                      0
                    )}
                    <br />
                  </p>
                </div>
              </div>
            ) : (
              <p>Loading ammo details...</p>
            ))}
        </div>
        {isEditing && (
          <div className="button-container">
            <button className="button is-primary" onClick={cancelEdit}>
              Cancel
            </button>
            <button className="button is-primary" onClick={confirmEdit}>
              Update
            </button>
          </div>
        )}
        {!isEditing && canAddNewFirearm === true && (
          <div className="button-container">
            <button className="edit-button is-primary" onClick={toggleEditMode}>
              Edit
            </button>
            <button className="edit-button is-primary" onClick={handleDelete}>
              Delete
            </button>
          </div>
        )}
        {!isEditing && canAddNewFirearm !== true && (
          <div className="button-container">
            <button className="edit-button is-primary" onClick={handleDelete}>
              Delete
            </button>
          </div>
        )}
      </div>
      <div
        className="page-right-container custom-page-right-container"
        id="acquisitionHistory"
      >
        <>
          {canAddNewFirearm === true && (
            <AddAmmoPurchaseForm handleSubmit={handleSubmit} />
          )}
          {acquisitionHistory &&
            acquisitionHistory.length > 0 &&
            acquisitionHistory.map((detail, index) => {
              const handleButtonClick = (e) => {
                e.stopPropagation(); // Prevent the event from bubbling up to the Link
                handleDeleteItem(detail.id); // Proceed with the deletion
              };
              return (
                <div key={detail.id} className="ammo-visit-detail-item">
                  <div className="ammo-visit-detail-main">
                    <button
                      className="ammo-visit-detail-list-delete-button"
                      onClick={handleButtonClick}
                      aria-label="Remove item"
                    >
                      ✕
                    </button>
                    <span className="ammo-visit-detail-list-value">
                      Date:{" "}
                      {moment(detail.acquisitionDate).format("YYYY-MM-DD")}
                    </span>
                    <span className="ammo-visit-detail-list-value">
                      {detail.rounds} rounds
                    </span>
                    <span className="ammo-visit-detail-list-value">
                      $ {detail.value}
                    </span>
                  </div>
                  <div className="ammo-visit-detail-notes">{detail.notes}</div>
                </div>
              );
            })}
        </>
      </div>
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
};

export const Ammo_Detail_Page = {
  path: "/ammo/:ammoId/dashboard",
  element: <AmmoDetails />,
};
