import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./AmmoList.css";

import "react-datepicker/dist/react-datepicker.css";
import { getFirearms } from "../../../common/Data/dataUtils";
import AddAmmoItem from "../../../common/AddAmmoItem/AddAmmoItem";
import AmmoListItem from "../../../common/Ammo/AmmoListItem";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
function App() {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    let tempMessage = "";
    if (userInfo) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#addAmmoButton",
      intro:
        "This is the ammo list page, all your ammos will be displayed here. Use the button on the bottom right to add new ammo",
    },
    {
      element: "#searchBar",
      intro: "Use the search bar to search your ammos",
    },
    {
      element: "#ammoListItem",
      intro:
        "For each ammo displayed here, you can click it to access the ammo detail page",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const [showLoading, setShowLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [ammos, setAmmos] = useState([]);
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [displayAmmos, setDisplayAmmos] = useState([]);
  const [displayAmmoDetail, setDisplayAmmoDetail] = useState(null);
  const [sortDirection, setSortDirection] = useState({
    field: "ammoAcquisitionDate",
    direction: "asc",
  }); // "asc" for ascending, "desc" for descending
  const [firearmsdata, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };
  const toggleSort = (field) => {
    setSortDirection((prevConfig) => {
      const isAsc =
        prevConfig.field === field && prevConfig.direction === "asc";
      return {
        field: field,
        direction: isAsc ? "desc" : "asc",
      };
    });
  };
  useEffect(() => {
    fetchFirearms();
  }, []);
  useEffect(() => {
    if (searchValue) {
      const lowercasedFilter = searchValue.toLowerCase();
      const filtered = ammos.filter((ammo) => {
        return (
          ammo.ammoName.toLowerCase().includes(lowercasedFilter) ||
          ammo.ammoCaliber.toLowerCase().includes(lowercasedFilter) ||
          ammo.ammoGrain.toLowerCase().includes(lowercasedFilter)
        );
      });
      setDisplayAmmos(filtered);
    } else {
      setDisplayAmmos(ammos); // if searchValue is empty, show all firearms
    }
  }, [searchValue, ammos]);
  useEffect(() => {
    setDisplayAmmos((prevAmmos) => {
      return [...prevAmmos].sort((a, b) => {
        let aValue = a[sortDirection.field];
        let bValue = b[sortDirection.field];

        const isAsc = sortDirection.direction === "asc";
        return isAsc ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
      });
    });
  }, [sortDirection]); // Re-sort whenever the sort configuration changes
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowLoading(true);
    fetchAmmos();
    setShowModal(false);
  };
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value); // Update state with new input value
  };

  const updateAmmoData = (newAmmoDetail) => {
    const updatedAmmos = ammos.map((ammo) =>
      ammo._id === newAmmoDetail._id ? newAmmoDetail : ammo
    );
    setAmmos(updatedAmmos);
    setShowLoading(false);
  };

  const removeAmmoData = (removeId) => {
    const updatedAmmos = ammos.filter((ammo) => ammo._id !== removeId);
    setAmmos(updatedAmmos);
    setShowLoading(false);
  };

  const fetchAmmos = async () => {
    const response = await fetch("/api/ammos");
    const data = await response.json();
    console.log(data);
    setAmmos(data);
    setShowLoading(false);
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchAmmos();
  }, []);

  return (
    <div className="ammo-list-page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      {/* Semi-transparent background image overlay */}
      <div className="page-background ammo-list-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          placeholder="Enter text"
          className="firearm-search-bar input"
          value={searchValue} // Bind the input value to the state
          onChange={handleSearchInputChange} // Set the onChange handler
          id="searchBar"
        />
        {/* <button className="button firearm-display-switch-button">
          Switch View
        </button> */}
      </div>
      <div
        className={`columns is-multiline fixed-height-columns-list-view is-full"
        }`}
        id="ammoListItem"
      >
        <div className="ammo-header">
          <div onClick={() => toggleSort("ammoName")}>
            Name{" "}
            {sortDirection.field === "ammoName"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("ammoCaliber")}>
            Caliber{" "}
            {sortDirection.field === "ammoCaliber"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("ammoGrain")}>
            Grain{" "}
            {sortDirection.field === "ammoGrain"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div>Total Cost ($)</div>
          <div>Total Rounds </div>
        </div>
        {displayAmmos.length > 0 &&
          displayAmmos.map((ammo, index) => {
            const firearm = firearmsdata.find(
              (f) => f._id === ammo.ammoLinkedFirearm
            );
            return (
              <div className="list-layout" key={ammo._id}>
                <AmmoListItem key={ammo._id} ammo={ammo} />
              </div>
            );
          })}
      </div>
      {!showModal && canAddNewFirearm === true && (
        <button
          className="button is-primary fixed-add-button"
          onClick={openModal}
        >
          + Add Ammo
        </button>
      )}
      {!showModal && canAddNewFirearm !== true && message && (
        <button className="button is-primary fixed-add-button">
          {message}
        </button>
      )}
      <AddAmmoItem isOpen={showModal} onClose={closeModal} />
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Ammo_List = {
  path: "/ammo/list",
  element: <App></App>,
};
