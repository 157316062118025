import "../../common/style.css";
import "./Contact.css";

import React from "react";

function Contact() {
  return (
    <div className="contact-page-container">
      <div className="page-background contact-background-image"></div>
      <div className="contact-container">
        <h2>Contact Us</h2>
        <p>
          If you need support or have any questions, feel free to reach out to
          us!
        </p>
        <p>
          You can send us an email at:{" "}
          <a href="mailto:support@armorysync.com">support@armorysync.com</a>
        </p>
        <p>
          Or join our Discord community here:{" "}
          <a
            href="https://discord.gg/WvtcH9Hwhe"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Discord
          </a>
        </p>
      </div>
    </div>
  );
}

export const Contact_Page = {
  path: "/contact",
  element: <Contact></Contact>,
};
