import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import "./ModificationListItem.css";

const ModificationListItem = ({ modification, firearm, onClick }) => {
  return (
    <div
      className="modification-list-item"
      onClick={() => onClick(modification)}
    >
      <div>
        {new Date(modification.modificationDate).toISOString().split("T")[0]}
      </div>
      <div>{firearm}</div>
      <div>{modification.modificationCost}</div>
      <div>{modification.modificationNote}</div>
    </div>
  );
};

export default ModificationListItem;
