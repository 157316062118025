import { getPasscode } from "../AESEncrypt/passcodeUtils";
import { decryptItem } from "../AESEncrypt/decryptionUtils";

const loadFirearms = async (url, storageKey) => {
  const response = await fetch(url);
  const encryptedData = await response.json();

  // Get the user's passcode from session storage
  const passcode = getPasscode();
  if (!passcode) {
    console.log("Passcode is not available");
    return;
  }

  // Decrypt the entire dataset
  const decryptedData = encryptedData.map((item) => decryptItem(item));

  sessionStorage.setItem(storageKey, JSON.stringify(decryptedData));
};

const getFirearms = (storageKey) => {
  const storedData = sessionStorage.getItem(storageKey);
  return JSON.parse(storedData);
};

export { loadFirearms, getFirearms };
