import React from "react";
import { useState, useEffect, useContext } from "react";
import FirearmCard from "../../../common/FirearmCard/FirearmCard";
import FirearmCardList from "../../../common/FirearmCard/FirearmCardList";
import AddFirearmItem from "../../../common/AddFirearmItem/AddFirearmItem";

import "../../../common/style.css";
import "./FirearmList.css";
import { getFirearms } from "../../../common/Data/dataUtils";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
function Firearm_List() {
  const steps = [
    {
      element: "#addFirearmButton",
      intro:
        "This is the firearm list page, all your firearms will be displayed here. Use the button on the bottom right to add new firearm",
    },
    {
      element: "#switchViewButton",
      intro:
        "Click the `Switch View` Button to switch between gallery view and list view to display your firearm",
    },
    {
      element: "#searchBar",
      intro: "Use the search bar to search your firearms",
    },
    {
      element: "#firearmListItem",
      intro:
        "For each firearm displayed here, you can click it to access the firearm detail page",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const [firearms, setFirearms] = useState([]);
  const [displayFirearms, setDisplayFirearms] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortDirection, setSortDirection] = useState({
    field: "firearmName",
    direction: "asc",
  }); // "asc" for ascending, "desc" for descending
  const [showModal, setShowModal] = useState(false);
  const [layoutType, setLayoutType] = useState("grid"); // 'grid' or 'vertical'
  const [showLoading, setShowLoading] = useState(true);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
    setShowLoading(false);
  };
  useEffect(() => {
    fetchFirearms();
  }, []);
  const userInfo = useContext(UserInfoContext);
  useEffect(() => {
    if (searchValue) {
      const lowercasedFilter = searchValue.toLowerCase();
      const filtered = firearms.filter((firearm) => {
        return (
          firearm.firearmName.toLowerCase().includes(lowercasedFilter) ||
          firearm.firearmType.toLowerCase().includes(lowercasedFilter) ||
          firearm.firearmMake.toLowerCase().includes(lowercasedFilter) ||
          firearm.firearmModel.toLowerCase().includes(lowercasedFilter) ||
          firearm.firearmCaliber.toLowerCase().includes(lowercasedFilter) ||
          (firearm.firearmPrice &&
            firearm.firearmPrice.toLowerCase().includes(lowercasedFilter)) ||
          firearm.firearmAcquisitionDate
            .toLowerCase()
            .includes(lowercasedFilter)
        );
      });
      setDisplayFirearms(filtered);
    } else {
      setDisplayFirearms(firearms); // if searchValue is empty, show all firearms
    }
  }, [searchValue, firearms]);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    fetchFirearms();
  };

  const toggleLayout = () => {
    setLayoutType((prevLayoutType) =>
      prevLayoutType === "grid" ? "vertical" : "grid"
    );
  };
  const handleInputChange = (event) => {
    setSearchValue(event.target.value); // Update state with new input value
  };

  useEffect(() => {
    console.log(searchValue); // Now this will log changes after the state updates
  }, [searchValue]); // Dependency array makes this effect run whenever searchValue changes

  const toggleSort = (field) => {
    setSortDirection((prevConfig) => {
      const isAsc =
        prevConfig.field === field && prevConfig.direction === "asc";
      return {
        field: field,
        direction: isAsc ? "desc" : "asc",
      };
    });
  };

  useEffect(() => {
    setDisplayFirearms((prevFirearms) => {
      return [...prevFirearms].sort((a, b) => {
        let aValue = a[sortDirection.field];
        let bValue = b[sortDirection.field];

        // If the sort field is 'firearmPrice', parse values as floats for numeric comparison
        if (sortDirection.field === "firearmPrice") {
          aValue = parseFloat(aValue) || 0; // Default to 0 if not a number
          bValue = parseFloat(bValue) || 0;
        }

        if (sortDirection.field === "firearmAcquisitionDate") {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        const isAsc = sortDirection.direction === "asc";
        return isAsc ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
      });
    });
  }, [sortDirection]); // Re-sort whenever the sort configuration changes

  const [canAddNewFirearm, setAccess] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    // Default access is set to false
    let access = false;
    let tempMessage = "";
    if (userInfo && firearms) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (firearms.length >= userInfo.maximumFirearmNumber) {
        access = false;
        tempMessage = "You have reached your firearm number limit.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo, firearms]);

  useEffect(() => {
    console.log(canAddNewFirearm);
  }, [canAddNewFirearm]);

  return (
    <>
      <div className="custom-page-container">
        <Steps
          enabled={helperEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
        />
        <div className="page-background firearm-page-background-image"></div>
        {showLoading && <div className="loading-indicator">Processing...</div>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            placeholder="Enter text"
            className="firearm-search-bar input"
            value={searchValue} // Bind the input value to the state
            onChange={handleInputChange} // Set the onChange handler
            id="searchBar"
          />
          <button
            className="button firearm-display-switch-button"
            onClick={toggleLayout}
            id="switchViewButton"
          >
            Switch View
          </button>
        </div>
        <div
          className={`columns is-multiline ${
            layoutType === "grid"
              ? "fixed-height-columns is-one-fifth"
              : "fixed-height-columns-list-view is-full"
          }`}
        >
          {layoutType !== "grid" && (
            <div className="firearms-header">
              <div onClick={() => toggleSort("firearmName")}>
                Name{" "}
                {sortDirection.field === "firearmName"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmType")}>
                Type{" "}
                {sortDirection.field === "firearmType"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmMake")}>
                Make{" "}
                {sortDirection.field === "firearmMake"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmModel")}>
                Model{" "}
                {sortDirection.field === "firearmModel"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmCaliber")}>
                Caliber{" "}
                {sortDirection.field === "firearmCaliber"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmPrice")}>
                Price{" "}
                {sortDirection.field === "firearmPrice"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmAcquisitionMethod")}>
                Acquisition Method{" "}
                {sortDirection.field === "firearmAcquisitionMethod"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
              <div onClick={() => toggleSort("firearmAcquisitionDate")}>
                Acquisition Date{" "}
                {sortDirection.field === "firearmAcquisitionDate"
                  ? sortDirection.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </div>
            </div>
          )}

          {displayFirearms.map((firearm) => (
            <div
              className={`${
                layoutType === "grid" ? "column is-one-fifth" : "list-layout"
              }`}
              key={firearm._id}
            >
              {layoutType === "grid" ? (
                <FirearmCard
                  key={firearm._id}
                  firearm={firearm}
                  displayOnly={true}
                />
              ) : (
                <FirearmCardList
                  key={firearm._id}
                  firearm={firearm}
                  displayOnly={true}
                />
              )}
            </div>
          ))}
        </div>
        {!showModal && canAddNewFirearm === true && (
          <button
            className="button is-primary fixed-add-button"
            onClick={openModal}
          >
            + Add New Firearm
          </button>
        )}
        {!showModal && canAddNewFirearm !== true && message && (
          <button className="button is-primary fixed-add-button">
            {message}
          </button>
        )}
        <AddFirearmItem isOpen={showModal} onClose={closeModal} />
        <button
          className="button is-primary fixed-help-button"
          onClick={onHelperClick}
        >
          ?
        </button>
      </div>
    </>
  );
}

export const FirearmList_Page = {
  path: "/firearm/list",
  element: <Firearm_List />,
};
