import "./ImageModal.css";
export const ImageModal = ({ src, alt, onClose, onSwitch }) => (
  <div className="modal is-active">
    <div className="modal-background" onClick={onClose}></div>
    <div className="modal-content">
      <p className="focus-modal-image">
        <img src={src} alt={alt} />
      </p>
      <div className="buttons is-centered">
        <button className="button is-info" onClick={onSwitch}>
          Switch
        </button>
        <button className="button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
    <button
      className="modal-close is-large"
      aria-label="close"
      onClick={onClose}
    ></button>
  </div>
);
