import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css"; // Ensure Bulma is imported if not already globally
import DatePicker from "react-datepicker";
import "./AddMaintenanceItem.css";
import "../style.css";
import { getPasscode } from "../AESEncrypt/passcodeUtils";
import { getFirearms } from "../Data/dataUtils";
function AddMaintenanceItem({ isOpen, onClose }) {
  const [notification, setNotification] = useState({
    message: "Maintenance Date is required.",
    visible: false,
  });
  const [maintenanceData, setMaintenanceData] = useState({
    maintenanceDate: "",
    maintenanceFirearm: "",
    maintenanceNote: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firearms, setFirearms] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setMaintenanceData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setMaintenanceData((prev) => ({ ...prev, maintenanceDate: date }));
  };

  const handleCancel = () => {
    setMaintenanceData({
      maintenanceDate: "",
      maintenanceFirearm: "",
      maintenanceNote: "",
    });
    onClose();
  };
  const validateForm = () => {
    const newErrors = {};
    // List all fields that require validation
    const fields = ["maintenanceDate"];
    fields.forEach((field) => {
      if (!maintenanceData[field]) {
        newErrors[field] = `The ${field.replace(
          "maintenance",
          ""
        )} field is required.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    const passcode = getPasscode();
    console.log(passcode);
    if (!passcode) {
      // Prompt the user to enter the passcode again
      return;
    }
    event.preventDefault();

    if (!validateForm()) {
      console.log("Not valid");
      const errorMessages = "Maintenance Date is required.";
      //const errorMessages = await Object.values(errors).join(" "); // Join all error messages into one string
      setNotification({
        message: errorMessages,
        visible: true,
      });
      setTimeout(() => {
        setNotification({
          message: "Maintenance Date is required.",
          visible: false,
        });
      }, 5000);
      return; // Prevent form submission if validation fails
    }
    setIsLoading(true);

    console.log(maintenanceData);

    const response = await fetch("/api/maintenance", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(maintenanceData),
    });
    if (!response.ok) {
      throw new Error("Failed to submit maintenance data");
    }
    console.log(response);
    setMaintenanceData({
      maintenanceDate: "",
      maintenanceFirearm: "",
      maintenanceNote: "",
    });
    onClose();
    setIsLoading(false);
  };

  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchFirearms();
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal is-active">
      {isLoading && <div className="loading-indicator">Processing...</div>}
      {notification.visible && (
        <div className="notification">{notification.message}</div>
      )}
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Maintenance</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <form
            id="firearm-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="field">
              <label className="label">Which firearm?</label>
              <div className="select">
                <select
                  name="maintenanceFirearm"
                  value={maintenanceData.maintenanceFirearm}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>{" "}
                  {firearms.map((firearm) => (
                    <option key={firearm._id} value={firearm._id}>
                      {firearm.firearmName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">Notes</label>
              <div className="control">
                <textarea
                  placeholder="Notes"
                  className="input input-notes"
                  type="text"
                  name="maintenanceNote"
                  value={maintenanceData.maintenanceNote}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Maintenance Date</label>
              <div className="control">
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={maintenanceData.maintenanceDate}
                  onChange={handleDateChange}
                  placeholderText="Date of maintenance"
                  className="custom-datepicker"
                />
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button
            className="add-firearm-button is-success"
            type="submit"
            form="firearm-form"
          >
            Add
          </button>
          <button
            className="add-firearm-button cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default AddMaintenanceItem;
