import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import "./AmmoListItem.css";
import { Link } from "react-router-dom";
const AmmoListItem = ({ ammo }) => {
  return (
    <Link
      to={`/ammo/${ammo._id}/dashboard`}
      className="ammos-list-item-container nodecoration"
    >
      <div className="ammo-list-item">
        <div>{ammo.ammoName}</div>
        <div>{ammo.ammoCaliber}</div>
        <div>{ammo.ammoGrain}</div>
        <div>
          {ammo.ammoAcquisitionHistory.reduce(
            (acc, item) => acc + parseInt(item.value, 10),
            0
          )}
        </div>
        <div>
          {ammo.ammoAcquisitionHistory.reduce(
            (acc, item) => acc + parseInt(item.rounds, 10),
            0
          )}
        </div>
      </div>
    </Link>
  );
};

export default AmmoListItem;
