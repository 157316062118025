import React, { useState } from "react";
import "./AddAmmoPurchaseItem.css";
import DatePicker from "react-datepicker";
export default function AddAmmoPurchaseForm({ handleSubmit }) {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [value, setCost] = useState("");
  const [rounds, setRounds] = useState("");
  const [notes, setNotes] = useState("");
  const [acquisitionDate, setAcquisitionDate] = useState(new Date());
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleCancel = () => {
    setIsFormVisible(false);
  };

  const handleInternalSubmit = (event) => {
    event.preventDefault(); // Prevent default if it was a form
    handleSubmit(value, rounds, notes, acquisitionDate); // Call the passed handleSubmit with current state
    setIsFormVisible(false); // Optionally close form after submit
  };

  const handleDateChange = (date) => {
    setAcquisitionDate(date);
  };
  return (
    <div className="ammo-add-item">
      {/* Toggle button */}
      <div className="ammo-add-item-trigger-button">
        <button onClick={toggleFormVisibility}>
          {isFormVisible ? "-" : "Add New Acquisition"}
        </button>
      </div>

      {/* Floating form-container */}
      {isFormVisible && (
        <form
          className="ammo-form-container"
          onSubmit={handleInternalSubmit}
          encType="multipart/form-data"
          id="ammo-purchase-form"
        >
          <input
            type="number"
            step="0.01"
            placeholder="Cost ($)"
            value={value}
            onChange={(e) => setCost(e.target.value)}
            required
          />
          <input
            type="number"
            step="1"
            placeholder="How many rounds?"
            value={rounds}
            onChange={(e) => setRounds(e.target.value)}
            required
          />
          <textarea
            placeholder="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            required
          ></textarea>
          <div>
            <DatePicker
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={acquisitionDate}
              onChange={handleDateChange}
              placeholderText="Your date of acquisition"
              className="ammo-custom-datepicker"
            />
          </div>

          <div className="ammo-form-actions">
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" form="ammo-purchase-form">
              Add
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
