import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./MaintenanceList.css";

import "react-datepicker/dist/react-datepicker.css";
import { getFirearms } from "../../../common/Data/dataUtils";
import AddMaintenanceItem from "../../../common/AddMaintenanceItem/AddMaintenanceItem";
import MaintenanceListItem from "../../../common/Maintenance/MaintenanceListItem";
import MaintenanceDetailBlock from "../../../common/Maintenance/MaintenanceDetailBlock";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
function App() {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    let tempMessage = "";
    if (userInfo) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#addMaintenanceButton",
      intro:
        "This is the maintenance list page, all your maintenances will be displayed here. Use the button on the bottom right to add new maintenance history",
    },
    {
      element: "#searchBar",
      intro: "Use the search bar to search your maintenances",
    },
    {
      element: "#maintenanceListItem",
      intro:
        "For each maintenance displayed here, you can click it to display the detail, and edit it",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const [showLoading, setShowLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [maintenances, setMaintenances] = useState([]);
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [displayMaintenances, setDisplayMaintenances] = useState([]);
  const [displayMaintenanceDetail, setDisplayMaintenanceDetail] =
    useState(null);
  const [sortDirection, setSortDirection] = useState({
    field: "maintenanceDate",
    direction: "asc",
  }); // "asc" for ascending, "desc" for descending
  const [firearmsdata, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };
  const toggleSort = (field) => {
    setSortDirection((prevConfig) => {
      const isAsc =
        prevConfig.field === field && prevConfig.direction === "asc";
      return {
        field: field,
        direction: isAsc ? "desc" : "asc",
      };
    });
  };
  useEffect(() => {
    fetchFirearms();
  }, []);
  useEffect(() => {
    if (searchValue) {
      const lowercasedFilter = searchValue.toLowerCase();
      const filtered = maintenances.filter((maintenance) => {
        const firearm = firearmsdata.find(
          (f) => f._id === maintenance.maintenanceFirearm
        );

        const firearmName = firearm
          ? firearm.firearmName.toLowerCase()
          : "unknown firearm";

        return (
          maintenance.maintenanceDate
            .toLowerCase()
            .includes(lowercasedFilter) ||
          firearmName.includes(lowercasedFilter) ||
          maintenance.maintenanceNote.toLowerCase().includes(lowercasedFilter)
        );
      });
      setDisplayMaintenances(filtered);
    } else {
      setDisplayMaintenances(maintenances); // if searchValue is empty, show all firearms
    }
  }, [searchValue, maintenances]);
  useEffect(() => {
    setDisplayMaintenances((prevMaintenances) => {
      return [...prevMaintenances].sort((a, b) => {
        let aValue = a[sortDirection.field];
        let bValue = b[sortDirection.field];

        if (sortDirection.field === "maintenanceDate") {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        } else if (sortDirection.field === "maintenanceFirearm") {
          const firearmA = firearmsdata.find((f) => f._id === aValue);
          const firearmB = firearmsdata.find((f) => f._id === bValue);
          aValue = firearmA ? firearmA.firearmName : "Unknown Firearm";
          bValue = firearmB ? firearmB.firearmName : "Unknown Firearm";
          console.log(aValue);
          console.log(bValue);
        }

        const isAsc = sortDirection.direction === "asc";
        return isAsc ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
      });
    });
  }, [sortDirection]); // Re-sort whenever the sort configuration changes
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowLoading(true);
    fetchMaintenances();
    setShowModal(false);
  };
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value); // Update state with new input value
  };

  const updateMaintenanceData = (newMaintenanceDetail) => {
    const updatedMaintenances = maintenances.map((maintenance) =>
      maintenance._id === newMaintenanceDetail._id
        ? newMaintenanceDetail
        : maintenance
    );
    setMaintenances(updatedMaintenances);
    setShowLoading(false);
  };

  const removeMaintenanceData = (removeId) => {
    const updatedMaintenances = maintenances.filter(
      (maintenance) => maintenance._id !== removeId
    );
    setMaintenances(updatedMaintenances);
    setShowLoading(false);
  };

  const fetchMaintenances = async () => {
    const response = await fetch("/api/maintenances");
    const data = await response.json();
    console.log(data);
    setMaintenances(data);
    setShowLoading(false);
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchMaintenances();
  }, []);

  const handleItemClick = (maintenance) => {
    console.log("Clicked maintenance:", maintenance);
    setDisplayMaintenanceDetail(maintenance);
    setIsDisplayDetail(true);

    // Perform other actions, like navigating to a detailed view, etc.
  };
  return (
    <div className="maintenance-list-page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      {/* Semi-transparent background image overlay */}
      <div className="page-background maintenance-list-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isDisplayDetail && (
        <MaintenanceDetailBlock
          onClose={() => setIsDisplayDetail(false)}
          maintenance={displayMaintenanceDetail}
          updateMaintenanceData={updateMaintenanceData}
          removeMaintenanceData={removeMaintenanceData}
          setShowLoading={setShowLoading}
        />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          placeholder="Enter text"
          className="firearm-search-bar input"
          value={searchValue} // Bind the input value to the state
          onChange={handleSearchInputChange} // Set the onChange handler
          id="searchBar"
        />
        {/* <button className="button firearm-display-switch-button">
          Switch View
        </button> */}
      </div>
      <div
        className={`columns is-multiline fixed-height-columns-list-view is-full"
        }`}
      >
        <div className="maintenance-header">
          <div onClick={() => toggleSort("maintenanceDate")}>
            Date{" "}
            {sortDirection.field === "maintenanceDate"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("maintenanceFirearm")}>
            Firearms{" "}
            {sortDirection.field === "maintenanceFirearm"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("maintenanceNote")}>
            Notes{" "}
            {sortDirection.field === "maintenanceNote"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
        </div>
        {displayMaintenances.length > 0 &&
          displayMaintenances.map((maintenance, index) => {
            const firearm = firearmsdata.find(
              (f) => f._id === maintenance.maintenanceFirearm
            );
            return (
              <div className="list-layout" key={maintenance._id}>
                <MaintenanceListItem
                  key={maintenance._id}
                  maintenance={maintenance}
                  firearm={firearm ? firearm.firearmName : "Unknown Firearm"}
                  onClick={handleItemClick}
                />
              </div>
            );
          })}
      </div>
      {!showModal && canAddNewFirearm === true && (
        <button
          className="button is-primary fixed-add-button"
          onClick={openModal}
        >
          + Add Maintenance
        </button>
      )}
      {!showModal && canAddNewFirearm !== true && message && (
        <button className="button is-primary fixed-add-button">
          {message}
        </button>
      )}
      <AddMaintenanceItem isOpen={showModal} onClose={closeModal} />
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Maintenance_List = {
  path: "/maintenance/list",
  element: <App></App>,
};
