import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import "./MaintenanceListItem.css";

const MaintenanceListItem = ({ maintenance, firearm, onClick }) => {
  return (
    <div className="maintenance-list-item" onClick={() => onClick(maintenance)}>
      <div>
        {new Date(maintenance.maintenanceDate).toISOString().split("T")[0]}
      </div>
      <div>{firearm}</div>
      <div>{maintenance.maintenanceNote}</div>
    </div>
  );
};

export default MaintenanceListItem;
