import "./AccessoryDetailBlock.css";
import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css"; // Ensure Bulma is imported if not already globally
import { getFirearms } from "../Data/dataUtils";
import DatePicker from "react-datepicker";
function AccessoryDetailBlock({
  accessory,
  onClose,
  updateAccessoryData,
  removeAccessoryData,
  setShowLoading,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [orgAccessoryDetail, setOrgAccessoryDetail] = useState(accessory);
  const [imagePreview, setImagePreview] = useState(accessory.accessoryImage);
  const onEdit = () => {
    setIsEditing(true);
  };
  console.log(accessory.accessoryImage);
  const onCancel = () => {
    setIsEditing(false);
    setAccessoryDetail(orgAccessoryDetail);
  };
  async function onDelete() {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this accessory log?"
    );
    if (isConfirmed) {
      console.log("Delete");
      setShowLoading(true);

      try {
        const parts = newAccessoryDetail.accessoryImage.split("/");
        const imageId = parts[parts.length - 2];
        const imageDeleteResponse = await fetch(
          `/api/delete/cloudflare/url/${imageId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log(imageDeleteResponse);

        if (!imageDeleteResponse.ok) {
          const error = await imageDeleteResponse.json();
          throw new Error(`Failed to delete image: ${error.message}`); // Throw to catch block
        }
      } catch (error) {}

      await fetch("/api/accessory/" + newAccessoryDetail._id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      removeAccessoryData(newAccessoryDetail._id);
      onClose();
    }
  }
  const [uploadUrl, setUploadUrl] = useState("");
  useEffect(() => {
    setCloudFlareUploadURL();
  }, []);
  const uploadImage = async () => {
    const formData = new FormData();
    if (imagePreview) {
      const blob = await fetch(imagePreview).then((r) => r.blob());
      formData.append("file", blob);
    }
    if (uploadUrl) {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const data = await response.json();
      console.log(data);
      return data.result.variants[0];
    }
  };
  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    if (response.uploadURL) {
      setUploadUrl(response.uploadURL);
    }
    return response.uploadURL;
  };
  async function onSubmit() {
    setShowLoading(true);

    // Handle potential image update
    let imageURL = newAccessoryDetail.accessoryImage; // Default to existing URL
    if (imageURL === "Changed") {
      console.log("not the same image");
      await setCloudFlareUploadURL();
      imageURL = await uploadImage(); // Assume this function uploads and returns the new URL
      console.log(imageURL);

      try {
        const parts = orgAccessoryDetail.accessoryImage.split("/");
        const imageId = parts[parts.length - 2];
        const imageDeleteResponse = await fetch(
          `/api/delete/cloudflare/url/${imageId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log(imageDeleteResponse);

        if (!imageDeleteResponse.ok) {
          const error = await imageDeleteResponse.json();
          throw new Error(`Failed to delete image: ${error.message}`); // Throw to catch block
        }
      } catch (error) {}
    }

    // Continue with updating the accessory details
    const updatedAccessoryDetail = {
      ...newAccessoryDetail,
      accessoryImage: imageURL, // Use new or existing image URL
    };
    console.log(updatedAccessoryDetail);

    await fetch("/api/accessory/" + updatedAccessoryDetail._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedAccessoryDetail),
    });

    setIsEditing(false);
    setOrgAccessoryDetail(updatedAccessoryDetail);
    updateAccessoryData(updatedAccessoryDetail);
    setShowLoading(false);
  }
  const [newAccessoryDetail, setAccessoryDetail] = useState(orgAccessoryDetail);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setAccessoryDetail((prev) => ({ ...prev, [name]: value }));
  };
  const handleDateChange = (date) => {
    setAccessoryDetail((prev) => ({ ...prev, accessoryAcquisitionDate: date }));
  };
  const [firearms, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchFirearms();
  }, []);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
        // Assuming you want to prepare to update the accessory detail with a new image URL before submitting
        setAccessoryDetail((prevState) => ({
          ...prevState,
          accessoryImage: "Changed", // Temporarily holding the data URL in state
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Accessory Detail</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          {isEditing ? (
            <>
              <div className="field">
                <label className="label">Accessory Image</label>
                <div className="control">
                  <input
                    className="input"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, image/webp, image/svg" // Specify accepted file types
                    onChange={handleImageChange}
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Which firearm?</label>
                <div className="select">
                  <select
                    name="accessoryLinkedFirearm"
                    value={newAccessoryDetail.accessoryLinkedFirearm}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>{" "}
                    <option value="none">No Firearm Linked</option>{" "}
                    {/* New option for no linked firearm */}
                    {firearms.map((firearm) => (
                      <option key={firearm._id} value={firearm._id}>
                        {firearm.firearmName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="field">
                <label className="label">Name</label>
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    name="accessoryName"
                    value={newAccessoryDetail.accessoryName}
                    onChange={handleInputChange}
                    placeholder="Enter name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Cost</label>
                <div className="input-group">
                  <span className="input-group-prepend">$</span>
                  <input
                    className="input"
                    type="text"
                    name="accessoryCost"
                    value={newAccessoryDetail.accessoryCost}
                    onChange={handleInputChange}
                    placeholder="Enter cost"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Source</label>
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    name="accessoryAcquisitionSource"
                    value={newAccessoryDetail.accessoryAcquisitionSource}
                    onChange={handleInputChange}
                    placeholder="Enter source"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Method</label>
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    name="accessoryAcquisitionMethod"
                    value={newAccessoryDetail.accessoryAcquisitionMethod}
                    onChange={handleInputChange}
                    placeholder="Enter method"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Notes</label>
                <div className="control">
                  <textarea
                    placeholder="Notes"
                    className="input input-notes"
                    type="text"
                    name="accessoryNote"
                    value={newAccessoryDetail.accessoryNote}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
              </div>

              <div className="field">
                <label className="label">Accessory Date</label>
                <div className="control">
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={newAccessoryDetail.accessoryAcquisitionDate}
                    onChange={handleDateChange}
                    placeholderText="Date of accessory"
                    className="custom-datepicker"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="field">
                <label className="label">Accessory Image</label>
                {orgAccessoryDetail.accessoryImage ? (
                  <img
                    src={orgAccessoryDetail.accessoryImage}
                    alt="Accessory Image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>
              <div className="field">
                <label className="label">Which firearm?</label>
                <p>
                  {firearms.find(
                    (f) => f._id === orgAccessoryDetail.accessoryLinkedFirearm
                  )?.firearmName || "No Firearm Linked"}
                </p>
              </div>
              <div className="field">
                <label className="label">Name</label>
                <p>{orgAccessoryDetail.accessoryName}</p>
              </div>
              <div className="field">
                <label className="label">Cost</label>
                <p>{orgAccessoryDetail.accessoryAcquisitionPrice}</p>
              </div>
              <div className="field">
                <label className="label">Source</label>
                <p>{orgAccessoryDetail.accessoryAcquisitionSource}</p>
              </div>
              <div className="field">
                <label className="label">Method</label>
                <p>{orgAccessoryDetail.accessoryAcquisitionMethod}</p>
              </div>
              <div className="field">
                <label className="label">Notes</label>
                <p>{orgAccessoryDetail.accessoryNote}</p>
              </div>
              <div className="field">
                <label className="label">Date</label>
                <p>
                  {
                    new Date(orgAccessoryDetail.accessoryAcquisitionDate)
                      .toISOString()
                      .split("T")[0]
                  }
                </p>
              </div>

              <div className="field">
                <label className="label">Notes</label>
                <p>{orgAccessoryDetail.accessoryNote}</p>
              </div>
            </>
          )}
        </section>
        <footer className="modal-card-foot">
          {isEditing ? (
            <>
              <button className="button is-info" onClick={onCancel}>
                Cancel
              </button>
              <button className="button" onClick={onSubmit}>
                Submit
              </button>
            </>
          ) : (
            <>
              <button className="button is-info" onClick={onEdit}>
                Edit
              </button>
              <button className="button" onClick={onDelete}>
                Delete
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
}

export default AccessoryDetailBlock;
