import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css"; // Ensure Bulma is imported if not already globally
import DatePicker from "react-datepicker";
import "./AddAmmoItem.css";
import "../style.css";
import { getPasscode } from "../AESEncrypt/passcodeUtils";
import { getFirearms } from "../Data/dataUtils";
function AddAmmoItem({ isOpen, onClose }) {
  const [notification, setNotification] = useState({
    message: "",
    visible: false,
  });
  const [ammoData, setAmmoData] = useState({
    ammoName: "",
    ammoCaliber: "",
    ammoImage: "",
    ammoGrain: "",
    ammoAcquisitionHistory: [],
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firearms, setFirearms] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setAmmoData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (isOpen) {
      setCloudFlareUploadURL();
    }
  }, [isOpen]);

  const handleCancel = () => {
    setAmmoData({
      ammoName: "",
      ammoCaliber: "",
      ammoImage: "",
      ammoGrain: "",
      ammoAcquisitionHistory: [],
    });
    onClose();
  };
  const validateForm = () => {
    const newErrors = {};
    // List all fields that require validation
    const fields = ["ammoName", "ammoCaliber", "ammoGrain"];

    fields.forEach((field) => {
      if (!ammoData[field]) {
        newErrors[field] = `The ${field.replace(
          "ammo",
          ""
        )} field is required.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const setCloudFlareUploadURL = async () => {
    const result = await fetch("/api/upload/cloudflare/url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await result.json();
    if (response.uploadURL) {
      setUploadUrl(response.uploadURL);
    }
    return response.uploadURL;
  };

  const handleSubmit = async (event) => {
    const passcode = getPasscode();
    console.log(passcode);
    if (!passcode) {
      // Prompt the user to enter the passcode again
      return;
    }
    event.preventDefault();

    if (!validateForm()) {
      console.log("Not valid");
      const errorMessages = await Object.values(errors).join(" "); // Join all error messages into one string
      setNotification({
        message: errorMessages,
        visible: true,
      });
      setTimeout(() => {
        setNotification({ message: "Title is required.", visible: false });
      }, 5000);
      return; // Prevent form submission if validation fails
    }
    setIsLoading(true);

    await setCloudFlareUploadURL();
    const URL = await uploadImage();

    const completeData = {
      ...ammoData,
      ammoImage: URL,
    };

    const response = await fetch("/api/ammo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(completeData),
    });
    if (!response.ok) {
      throw new Error("Failed to submit ammo data");
    }
    console.log(response);
    setAmmoData({
      ammoName: "",
      ammoCaliber: "",
      ammoImage: "",
      ammoGrain: "",
      ammoAcquisitionHistory: [],
    });
    onClose();
    setIsLoading(false);
  };

  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchFirearms();
  }, []);

  const handleImageChange = (event) => {
    setIsImage(false);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
      setIsImage(true);
    }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    if (imagePreview) {
      const blob = await fetch(imagePreview).then((r) => r.blob());
      formData.append("file", blob);
    }
    if (uploadUrl) {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        return "";
      }
      const data = await response.json();
      console.log(data);
      return data.result.variants[0];
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal is-active">
      {isLoading && <div className="loading-indicator">Processing...</div>}
      {notification.visible && (
        <div className="notification">{notification.message}</div>
      )}
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Ammo</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <form
            id="firearm-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="field">
              <label className="label">Ammo Image</label>
              <div className="control">
                <input
                  className="input"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp, image/svg" // Specify accepted file types
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </div>
            </div>

            <div className="field">
              <label className="label">Name</label>
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  name="ammoName"
                  value={ammoData.ammoName}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Caliber</label>
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  name="ammoCaliber"
                  value={ammoData.ammoCaliber}
                  onChange={handleInputChange}
                  placeholder="Enter cost"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Grain</label>
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  name="ammoGrain"
                  value={ammoData.ammoGrain}
                  onChange={handleInputChange}
                  placeholder="Enter source"
                />
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button
            className="add-firearm-button is-success"
            type="submit"
            form="firearm-form"
          >
            Add
          </button>
          <button
            className="add-firearm-button cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default AddAmmoItem;
