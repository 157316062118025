import React, { useState, useEffect } from "react";
import UserInfoContext from "./UserInfoContext";

function findClaimValue(claims, claimType) {
  const claim = claims.find((c) => c.typ === claimType);
  return claim ? claim.val : null;
}
function UserInfoProvider({ children }) {
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    (async () => {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const { clientPrincipal } = payload;
      if (clientPrincipal && clientPrincipal.claims) {
        const userEmail = findClaimValue(clientPrincipal.claims, "emails");
        if (userEmail) {
          // Call the readUserByEmail API
          const userResponse = await fetch("/api/subscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: userEmail }),
          });

          if (userResponse.ok) {
            const userPayload = await userResponse.json();
            // Update userInfo with detailed user data from the API
            setUserInfo(userPayload.user);
          } else {
            console.error(
              "Failed to fetch user details:",
              await userResponse.text()
            );
            // Optionally handle errors or set default user info
            setUserInfo(null);
          }
        } else {
          console.error("Email claim not found");
          // Optionally handle cases where email isn't found
          setUserInfo(null);
        }
      } else {
        setUserInfo(null);
      }
    })();
  }, []);

  return (
    <UserInfoContext.Provider value={userInfo}>
      {children}
    </UserInfoContext.Provider>
  );
}

export default UserInfoProvider;
