// decryptionUtils.js
const { decryptObject } = require("./encryptionUtils");
const { getPasscode } = require("./passcodeUtils");

const decryptItem = (encrypted) => {
  try {
    const passcode = getPasscode();
    if (!passcode) {
      throw new Error("Passcode is missing");
    }

    const decryptedData = decryptObject(encrypted, passcode);
    const mergedData = {
      _id: encrypted._id,
      userid: encrypted.userid,
      firearmRangeVisitHistory: encrypted.firearmRangeVisitHistory,
      ...decryptedData,
    };
    return mergedData;
  } catch (error) {
    console.error("Error decrypting firearm:", error);
    return encrypted; // Return the original field in case of error
  }
};

module.exports = { decryptItem };
