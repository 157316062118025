import "../../common/style.css";
import "./FAQ.css";

import React from "react";
import faqData from "./faqData.json"; // Import the JSON data directly

function FAQ() {
  return (
    <div className="faq-page-container">
      <div className="page-background faq-background-image"></div>
      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        {faqData.length > 0 ? (
          faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <h3>{item.question}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
            </div>
          ))
        ) : (
          <p>No FAQs available at this time.</p>
        )}
      </div>
    </div>
  );
}

export const Faq_Page = {
  path: "/faq",
  element: <FAQ></FAQ>,
};
