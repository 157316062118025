import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./AccessoryList.css";

import "react-datepicker/dist/react-datepicker.css";
import { getFirearms } from "../../../common/Data/dataUtils";
import AddAccessoryItem from "../../../common/AddAccessoryItem/AddAccessoryItem";
import AccessoryListItem from "../../../common/Accessory/AccessoryListItem";
import AccessoryDetailBlock from "../../../common/Accessory/AccessoryDetailBlock";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import UserInfoContext from "../../../UserInfoContext";
function App() {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    let tempMessage = "";
    if (userInfo) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#addAccessoryButton",
      intro:
        "This is the accessory list page, all your accessories will be displayed here. Use the button on the bottom right to add new accessory",
    },
    {
      element: "#searchBar",
      intro: "Use the search bar to search your accessories",
    },
    {
      element: "#ammoListItem",
      intro:
        "For each ammo displayed here, you can click it to display the detail, and edit it",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const [showLoading, setShowLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [accessorys, setAccessorys] = useState([]);
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [displayAccessorys, setDisplayAccessorys] = useState([]);
  const [displayAccessoryDetail, setDisplayAccessoryDetail] = useState(null);
  const [sortDirection, setSortDirection] = useState({
    field: "accessoryAcquisitionDate",
    direction: "asc",
  }); // "asc" for ascending, "desc" for descending
  const [firearmsdata, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };
  const toggleSort = (field) => {
    setSortDirection((prevConfig) => {
      const isAsc =
        prevConfig.field === field && prevConfig.direction === "asc";
      return {
        field: field,
        direction: isAsc ? "desc" : "asc",
      };
    });
  };
  useEffect(() => {
    fetchFirearms();
  }, []);
  useEffect(() => {
    if (searchValue) {
      const lowercasedFilter = searchValue.toLowerCase();
      const filtered = accessorys.filter((accessory) => {
        const firearm = firearmsdata.find(
          (f) => f._id === accessory.accessoryLinkedFirearm
        );

        const firearmName = firearm
          ? firearm.firearmName.toLowerCase()
          : "unknown firearm";

        return (
          accessory.accessoryAcquisitionDate
            .toLowerCase()
            .includes(lowercasedFilter) ||
          firearmName.includes(lowercasedFilter) ||
          accessory.accessoryNote.toLowerCase().includes(lowercasedFilter)
        );
      });
      setDisplayAccessorys(filtered);
    } else {
      setDisplayAccessorys(accessorys); // if searchValue is empty, show all firearms
    }
  }, [searchValue, accessorys]);
  useEffect(() => {
    setDisplayAccessorys((prevAccessorys) => {
      return [...prevAccessorys].sort((a, b) => {
        let aValue = a[sortDirection.field];
        let bValue = b[sortDirection.field];

        if (sortDirection.field === "accessoryAcquisitionDate") {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        } else if (sortDirection.field === "accessoryLinkedFirearm") {
          const firearmA = firearmsdata.find((f) => f._id === aValue);
          const firearmB = firearmsdata.find((f) => f._id === bValue);
          aValue = firearmA ? firearmA.firearmName : "Unknown Firearm";
          bValue = firearmB ? firearmB.firearmName : "Unknown Firearm";
          console.log(aValue);
          console.log(bValue);
        }

        const isAsc = sortDirection.direction === "asc";
        return isAsc ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
      });
    });
  }, [sortDirection]); // Re-sort whenever the sort configuration changes
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowLoading(true);
    fetchAccessorys();
    setShowModal(false);
  };
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value); // Update state with new input value
  };

  const updateAccessoryData = (newAccessoryDetail) => {
    const updatedAccessorys = accessorys.map((accessory) =>
      accessory._id === newAccessoryDetail._id ? newAccessoryDetail : accessory
    );
    setAccessorys(updatedAccessorys);
    setShowLoading(false);
  };

  const removeAccessoryData = (removeId) => {
    const updatedAccessorys = accessorys.filter(
      (accessory) => accessory._id !== removeId
    );
    setAccessorys(updatedAccessorys);
    setShowLoading(false);
  };

  const fetchAccessorys = async () => {
    const response = await fetch("/api/accessorys");
    const data = await response.json();
    console.log(data);
    setAccessorys(data);
    setShowLoading(false);
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchAccessorys();
  }, []);

  const handleItemClick = (accessory) => {
    console.log("Clicked accessory:", accessory);
    setDisplayAccessoryDetail(accessory);
    setIsDisplayDetail(true);

    // Perform other actions, like navigating to a detailed view, etc.
  };
  return (
    <div className="accessory-list-page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      {/* Semi-transparent background image overlay */}
      <div className="page-background accessory-list-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}
      {isDisplayDetail && (
        <AccessoryDetailBlock
          onClose={() => setIsDisplayDetail(false)}
          accessory={displayAccessoryDetail}
          updateAccessoryData={updateAccessoryData}
          removeAccessoryData={removeAccessoryData}
          setShowLoading={setShowLoading}
        />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          placeholder="Enter text"
          className="firearm-search-bar input"
          value={searchValue} // Bind the input value to the state
          onChange={handleSearchInputChange} // Set the onChange handler
          id="searchBar"
        />
        {/* <button className="button firearm-display-switch-button">
          Switch View
        </button> */}
      </div>
      <div
        className={`columns is-multiline fixed-height-columns-list-view is-full"
        }`}
      >
        <div className="accessory-header">
          <div onClick={() => toggleSort("accessoryAcquisitionDate")}>
            Date{" "}
            {sortDirection.field === "accessoryAcquisitionDate"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryAcquisitionMethod")}>
            Method{" "}
            {sortDirection.field === "accessoryAcquisitionMethod"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryAcquisitionSource")}>
            Source{" "}
            {sortDirection.field === "accessoryAcquisitionSource"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryAcquisitionPrice")}>
            Price{" "}
            {sortDirection.field === "accessoryAcquisitionPrice"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryName")}>
            Name{" "}
            {sortDirection.field === "accessoryName"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryNote")}>
            Note{" "}
            {sortDirection.field === "accessoryNote"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
          <div onClick={() => toggleSort("accessoryLinkedFirearm")}>
            Firearms{" "}
            {sortDirection.field === "accessoryLinkedFirearm"
              ? sortDirection.direction === "asc"
                ? "↑"
                : "↓"
              : ""}
          </div>
        </div>
        {displayAccessorys.length > 0 &&
          displayAccessorys.map((accessory, index) => {
            const firearm = firearmsdata.find(
              (f) => f._id === accessory.accessoryLinkedFirearm
            );
            return (
              <div className="list-layout" key={accessory._id}>
                <AccessoryListItem
                  key={accessory._id}
                  accessory={accessory}
                  firearm={firearm ? firearm.firearmName : "No Firearm Linked"}
                  onClick={handleItemClick}
                />
              </div>
            );
          })}
      </div>
      {!showModal && canAddNewFirearm === true && (
        <button
          className="button is-primary fixed-add-button"
          onClick={openModal}
        >
          + Add Accessory
        </button>
      )}
      {!showModal && canAddNewFirearm !== true && message && (
        <button className="button is-primary fixed-add-button">
          {message}
        </button>
      )}
      <AddAccessoryItem isOpen={showModal} onClose={closeModal} />
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Accessory_List = {
  path: "/accessory/list",
  element: <App></App>,
};
