import "./ModificationDetailBlock.css";
import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css"; // Ensure Bulma is imported if not already globally
import { getFirearms } from "../Data/dataUtils";
import DatePicker from "react-datepicker";
function ModificationDetailBlock({
  modification,
  onClose,
  updateModificationData,
  removeModificationData,
  setShowLoading,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [orgModificationDetail, setOrgModificationDetail] =
    useState(modification);
  const onEdit = () => {
    setIsEditing(true);
  };
  const onCancel = () => {
    setIsEditing(false);
    setModificationDetail(orgModificationDetail);
  };
  async function onDelete() {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this modification log?"
    );
    if (isConfirmed) {
      console.log("Delete");
      setShowLoading(true);
      await fetch("/api/modification/" + newModificationDetail._id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      removeModificationData(newModificationDetail._id);
      onClose();
    }
  }
  async function onSubmit() {
    setShowLoading(true);
    await fetch("/api/modification/" + newModificationDetail._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newModificationDetail),
    });
    setIsEditing(false);
    setOrgModificationDetail(newModificationDetail);
    updateModificationData(newModificationDetail);
  }
  const [newModificationDetail, setModificationDetail] = useState(
    orgModificationDetail
  );
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setModificationDetail((prev) => ({ ...prev, [name]: value }));
  };
  const handleDateChange = (date) => {
    setModificationDetail((prev) => ({ ...prev, modificationDate: date }));
  };
  const [firearms, setFirearms] = useState([]);
  const fetchFirearms = async () => {
    setFirearms(getFirearms("firearms"));
  };

  // Fetch data on initial component mount
  useEffect(() => {
    fetchFirearms();
  }, []);
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Modification Detail</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          {isEditing ? (
            <>
              <div className="field">
                <label className="label">Which firearm?</label>
                <div className="select">
                  <select
                    name="modificationFirearm"
                    value={newModificationDetail.modificationFirearm}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>{" "}
                    {firearms.map((firearm) => (
                      <option key={firearm._id} value={firearm._id}>
                        {firearm.firearmName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="field">
                <label className="label">Cost</label>
                <div className="input-group">
                  <span className="input-group-prepend">$</span>
                  <input
                    className="input"
                    type="text"
                    name="modificationCost"
                    value={newModificationDetail.modificationCost}
                    onChange={handleInputChange}
                    placeholder="Enter cost"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Notes</label>
                <div className="control">
                  <textarea
                    placeholder="Notes"
                    className="input input-notes"
                    type="text"
                    name="modificationNote"
                    value={newModificationDetail.modificationNote}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="field">
                <label className="label">Modification Date</label>
                <div className="control">
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={newModificationDetail.modificationDate}
                    onChange={handleDateChange}
                    placeholderText="Date of modification"
                    className="custom-datepicker"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="field">
                <label className="label">Which firearm?</label>
                <p>
                  {firearms.find(
                    (f) => f._id === orgModificationDetail.modificationFirearm
                  )?.firearmName || "Unknown Firearm"}
                </p>
              </div>
              <div className="field">
                <label className="label">Date</label>
                <p>
                  {
                    new Date(orgModificationDetail.modificationDate)
                      .toISOString()
                      .split("T")[0]
                  }
                </p>
              </div>
              <div className="field">
                <label className="label">Cost</label>
                <p>{orgModificationDetail.modificationCost}</p>
              </div>
              <div className="field">
                <label className="label">Notes</label>
                <p>{orgModificationDetail.modificationNote}</p>
              </div>
            </>
          )}
        </section>
        <footer className="modal-card-foot">
          {isEditing ? (
            <>
              <button className="button is-info" onClick={onCancel}>
                Cancel
              </button>
              <button className="button" onClick={onSubmit}>
                Submit
              </button>
            </>
          ) : (
            <>
              <button className="button is-info" onClick={onEdit}>
                Edit
              </button>
              <button className="button" onClick={onDelete}>
                Delete
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
}

export default ModificationDetailBlock;
