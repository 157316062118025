import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "../../../common/style.css";
import "./RangeVisitDashboard.css";

import "react-datepicker/dist/react-datepicker.css";
import UserInfoContext from "../../../UserInfoContext";
import { useLoaderData } from "react-router-dom";
import SearchBox from "../../../common/GoogleMapSearch/GoogleMapSearch";
import RangeVisitItem from "../../../common/RangeVisitItem/RangeVisitItem";
import addNotification from "react-push-notification";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
async function loader({ request }) {
  const allRangeVisits = await fetch("/api/rangevisits", {
    signal: request.signal,
    method: "GET",
    headers: {
      userid: "",
    },
  });
  const rangevisits = await allRangeVisits.json();
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset the time to midnight for comparison

  // Filter and sort range visits
  const upcomingVisits = rangevisits
    .filter((visit) => {
      // Parse visit date and check if it's after today's date
      const visitDate = new Date(visit.visitDate);
      return visitDate >= today; // Only future dates
    })
    .sort((a, b) => {
      // Sort by date (closest to today first)
      const dateA = new Date(a.visitDate);
      const dateB = new Date(b.visitDate);
      return dateA - dateB; // Ascending order (closest first)
    });

  // The first item is the visit closest to today
  const closestVisit = upcomingVisits[0];

  if (closestVisit) {
    console.log("/rangevisit/" + closestVisit._id);
    addNotification({
      title: "Your next scheduled range visit is coming!",
      message: "click to see more details",
      duration: 5000,
      native: true,
      onClick: () => (window.location = "/rangevisit/" + closestVisit._id),
    });
  }

  return { rangevisits };
}

function App() {
  const [canAddNewFirearm, setAccess] = useState(true); // Default access is set to true
  const [message, setMessage] = useState("");
  const userInfo = useContext(UserInfoContext);
  console.log(userInfo);
  useEffect(() => {
    // Default access is set to false
    let access = false;
    // let access = true;
    let tempMessage = "";
    if (userInfo) {
      // Check if user role is admin
      if (userInfo.userRole === "admin") {
        tempMessage = "You have admin role";
        access = true;
      }
      // Check if isBeyond15Days is true and plan_type is 'Free'
      else if (
        userInfo.isBeyond15Days &&
        userInfo.latestSubscription.plan_type === "Free"
      ) {
        access = false;
        tempMessage = "You don't have a valid subscription plan.";
      }
      // Check if the number of firearms is greater than or equal to the maximum allowed
      else if (userInfo.latestSubscription.plan_type === "Starter") {
        access = false;
        tempMessage = "Access denied due to Starter subscription plan.";
      }
      // If none of the above, user can add new firearms
      else {
        access = true;
      }

      // Update the state based on the conditions
      setAccess(access);
      setMessage(tempMessage);
    }
  }, [userInfo]);
  const steps = [
    {
      element: "#rangeVisitDashboard",
      intro:
        "Welcome to the range visit dashboard, where you can add new range visit, and access existing range visit",
    },
    {
      element: "#map",
      intro:
        "You can use the search bar here to search the shooting range you have visited/plan to visit on the map. You need to give the permission to the website to use your current location in order to use this map function. If you accidentally click `Don't Allow`, simply refresh the page and choose again.",
    },
    {
      element: "#visitDate",
      intro: "Set the date of the range visit",
    },
    {
      element: "#visitDuration",
      intro: "Set the duration of the range visit",
    },
    {
      element: "#addButton",
      intro: "Click the button to add the range visit to your record",
    },
    {
      element: "#visitHistory",
      intro:
        "You can find all your range visits here. Click one to jump to its detail page. If you want to delete that range visit, click the circle icon on the top left corner.",
    },
  ];
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const onExit = () => {
    setHelperEnabled(false);
  };

  const onHelperClick = () => {
    setHelperEnabled(true);
  };
  const { rangevisits } = useLoaderData();
  const [allVisits, setVisits] = useState(rangevisits);
  console.log(allVisits);
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [rangeInfo, setRangeInfo] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    if (rangeInfo) {
      console.log(rangeInfo.coordinates.lat);
      console.log(rangeInfo.coordinates.lng);
      setRangeLat(rangeInfo.coordinates.lat);
      setRangeLng(rangeInfo.coordinates.lng);
    }
  }, [rangeInfo]);

  const handleRangeInfo = (info) => {
    setRangeInfo(info); // Update the state when the child passes data back
  };

  //Maybe later move to backend
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [rangeLat, setRangeLat] = useState();
  const [rangeLng, setRangeLng] = useState();
  const [duration, setDuration] = useState(60); // State for managing duration in minutes

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value); // Update duration based on user input
  };

  async function handleAddVisit() {
    setShowLoading(true);
    if (rangeInfo) {
      const formattedDate = selectedDate.toISOString();
      const newRangeVisit = {
        visitDate: formattedDate,
        rangeLat: rangeLat,
        rangeLng: rangeLng,
        duration: duration,
        rangeInfo: rangeInfo,
        visitDetail: [],
      };
      console.log(newRangeVisit);

      const result = await fetch("/api/rangevisit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRangeVisit),
      });
      if (result.ok) {
        const newlyAddedRangeVisit = await result.json();
        if (allVisits) {
          setVisits([newlyAddedRangeVisit, ...allVisits]);
        } else {
          setVisits([newlyAddedRangeVisit]);
        }
        addNotification({
          title: "A new range visit has been created",
          message: "click to add details",
          duration: 5000,
          native: true,
          onClick: () =>
            (window.location = "/rangevisit/" + newlyAddedRangeVisit._id),
        });
      }
    }
    setShowLoading(false);
  }

  async function handleDelete(id) {
    console.log("Delete");
    console.log(id);
    await fetch("/api/rangevisit/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    setVisits(allVisits.filter((visit) => visit._id !== id));
  }

  function success(pos) {
    const crd = pos.coords;
    setLat(crd.latitude);
    setLng(crd.longitude);

    console.log("Your current position is:");
    console.log(lat);
    console.log(lng);
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setShowLoading(false);
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  if (!lat || !lng) {
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  return (
    <div className="page-container range-visit-dashboard-custom-page-container">
      <Steps
        enabled={helperEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
      />
      {/* Semi-transparent background image overlay */}
      <div className="page-background rangevisit-dashboard-background-image"></div>
      {showLoading && <div className="loading-indicator">Processing...</div>}

      {canAddNewFirearm === true && (
        <>
          <div className="page-left-container">
            <div className="map-container" id="map">
              {!lat || !lng ? (
                <h1>Loading...</h1>
              ) : (
                <SearchBox lat={lat} lng={lng} onRangeInfo={handleRangeInfo} />
              )}
            </div>
            <div className="inputs-container">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Your date of visit"
                className="custom-datepicker"
                id="visitDate"
              />
              <div className="duration-container" id="visitDuration">
                <input
                  type="number"
                  value={duration}
                  onChange={handleDurationChange}
                  placeholder="Duration in minutes"
                  className="duration-input"
                />
                <span className="duration-label">min</span>
              </div>
            </div>
            <button
              className="add-visit-button"
              onClick={handleAddVisit}
              id="addButton"
            >
              Add new visit
            </button>
          </div>
        </>
      )}

      {canAddNewFirearm !== true && (
        <>
          <h1 className="access-deny">{message}</h1>
        </>
      )}

      {allVisits.length > 0 && (
        <div className="page-right-container" id="visitHistory">
          <div className="grid-container">
            {allVisits
              .sort((a, b) => {
                // Convert visit dates to Date objects for accurate comparison
                const dateA = new Date(a.visitDate);
                const dateB = new Date(b.visitDate);
                return dateB - dateA; // Ascending order (oldest to newest)
                // return dateB - dateA; // For descending order (newest to oldest)
              })
              .map((visit, index) => (
                <RangeVisitItem
                  key={index}
                  visit={visit}
                  onDelete={handleDelete}
                />
              ))}
          </div>
        </div>
      )}
      <button
        className="button is-primary fixed-help-button"
        onClick={onHelperClick}
      >
        ?
      </button>
    </div>
  );
}

export const Rangevisit_Dashboard = {
  path: "/rangevisit/dashboard",
  element: <App></App>,
  loader: loader,
};
